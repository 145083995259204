import React, { useState, useRef } from "react";
import "./Home.css";
import "./ResponsiveHome.css";
// import HomePageNotice from "../Home page Notice/HomePageNotice";

import newGif from ".././NEW/new.gif";

import { Link, NavLink } from "react-router-dom";

import vc from "../Logo/VC.jpg";
import chanslor from "../Logo/Vinai-Kumar-Saxena.webp";
import registrar from "../Logo/RegistrarImage.jpg";

// import newvcsirMaheshverma from "./HomePageData/vc-sir-rounded-modified.png";
import profVRavichandiran from "./HomePageData/prof v ravichandiran.jpeg";

import newregistrarShahooSIr from "./HomePageData/profPKSahooDirector.jpeg";

import { SidebarData } from "../Nav/SideNav/SidebarData";
import TakeATour from "../TakeATour/TakeATour";

// files
import { FaListAlt, FaRegWindowClose } from "react-icons/fa";
import DpsruHomeTabs from "../A tabs creation/DpsruHomeTabs";

// notification imported files
import vacantSeatforSpecial from "../Notifications/Notification Files/Vacant-Seats-for-Special-Spot-Round.pdf";
import specialSpotRoundC from "../Notifications/Notification Files/SPECIAL-SPOT-ROUND-COUNSELLING-Advertiesment-1.pdf";

import ReOpeningOfSemester from "../Notifications/Notification Files/CamScanner-08-09-2023-10.49.25_1.pdf";
import PhdVivaVocaNotiMsMeenuG from "../Notifications/Notification Files/PhD-Viva-Voce-Notification-of-Ms.-Meenu-Grover-Sharma.pdf";
import Circular4823 from "../Notifications/Notification Files/Circular-4.8.23.pdf";
import DocVerificationNotice from "../Notifications/Notification Files/document-verification-notice.pdf";
import NoticeforextensionoftheonlineapplicationdeadlinefortheBPharmLEandPGcourses from "../Notifications/Notification Files/WhatsApp-Image-2023-07-22-at-10.55.13-AM.jpeg";
import Circular02 from "../Notifications/Notification Files/Circular02.pdf";
import YogaAndFitness from "../Notifications/Notification Files/YogaAndFitness.pdf";
import Firstroundonlinecounsellingschedule202 from "../Notifications/Notification Files/First-round-online-counselling-schedule-2023.pdf";
import RevisedProvisionalMeritListsofDelhicandidatesforUGcourses2023 from "../Notifications/Notification Files/Revised-Provisional-Merit-Lists-of-Delhi-candidates-for-UG-courses-2023.pdf";
import RevisedProvisionalMeritListsofNONDelhicandidatesforUGcourses2023 from "../Notifications/Notification Files/Revised-Provisional-Merit-Lists-of-Non-Delhi-candidates-for-UG-courses-2023.pdf";
import AlphabeticalListofcandidateswhohavenotfilledcoursePreferences from "../Notifications/Notification Files/Alphabetical-List-of-candidates-who-have-not-filled-course-Preferences.pdf";
import Commoninstructionsforskilltestfornonteachingposts from "../Notifications/Notification Files/Common-instructions-for-skill-test-for-non-teaching-posts.pdf";
import NoticeregardingUGcoursespreferencefilling202324 from "../Notifications/Notification Files/Notice-regarding-UG-courses-preference-filling-2023-24.pdf";
import NOTICEofSkillTest from "../Notifications/Notification Files/NOTICE-of-Skill-Test.pdf";
import Document570 from "../Notifications/Notification Files/Document-570.pdf";
import EmployementNotice from "../Notifications/Notification Files/Employement-Notice.pdf";
import Prospectus2023forweb from "../Notifications/Notification Files/Prospectus-2023-for-web.pdf";
import AdvertisementofJRF from "../Notifications/Notification Files/Advertisement-of-JRF.pdf";
import Advertisement2023241 from "../Notifications/Notification Files/Advertisement-2023-24-1.pdf";
import PhdAdmission202324 from "../Notifications/Notification Files/Phd-Admission-2023-24.pdf";
import CorrigendumofNTS1 from "../Notifications/Notification Files/Corrigendum-of-NTS-1.pdf";

// new data on 9of oct 2023

import newdata001 from "../Notifications/Notification Files/Ph.D-Viva-Voce-Notification-of-Ms.-Shweta-Paroha.pdf";

import newdata002 from "../Notifications/Notification Files/Notification-for-the-Award-of-Ph.D-Degree-to-Ms.-Meenu-Grover-Sharma.pdf";
import newdata003 from "../Notifications/Notification Files/Date-extended-Notice-for-Final-Admission-Pre-PhD-2023-24.pdf";
import newdata004 from "../Notifications/Notification Files/List-of-final-admission-Pre-PhD-2023-24.pdf";
import newdata005 from "../Notifications/Notification Files/Notice-for-final-admission-Pre-Ph.D-2023-24.pdf";
import newdata006 from "../Notifications/Notification Files/Result-of-Ph.D.-Entrance-Examination-2023-24.pdf";
import newdata007 from "../Notifications/Notification Files/Notice-and-List-for-candidates-of-Pre-PhD-Interview-September-2023.pdf";
import newdata008 from "../Notifications/Notification Files/List-for-approved-Supervisors-September-2023.pdf";
import newdata009 from "../Notifications/Notification Files/MPT-Entrance-Result.pdf";
import newdata010 from "../Notifications/Notification Files/Revised-list.pdf";

import convoFiles from "../Notifications/Notification Files/Convocation-Notice.pdf";
import phdCourseWork from "../Notifications/Notification Files/pre-PhD-course-work.pdf";
import walkinInterview3010 from "../Notifications/Notification Files/Advertisement.pdf";
import oddSemFeeNotice from "../Students/StudentsData/odd semester fee notice.pdf";

import NotificationPhdDegreeMsSweta from "../Examination/ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Shweta Paroha_rotated.pdf";
import NotificationPhdDegreeMsNeha from "../Examination/ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Neha_rotated.pdf";
import UFMNotice from "../Examination/ExaminatonData/UFM Notice.pdf";

import circularForTakingIntegrityPledge from "../Notifications/Notification Files/Circular for taking integrity pledge.pdf";
import CircularForTakinganOrganistionalOath from "../Notifications/Notification Files/Circular for taking an organisational Oath on 3 November 2023 in DPSRU.pdf";
import convoForm6th from "../Notifications/Notification Files/6th convocation registration form 2023.pdf";
import walkinInterviewOn17Nov from "../Notifications/Notification Files/Interview on 17.11.23.pdf";
import walkinInterviewResult from "../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 03-11-2023.pdf";
import finalListOfEligibleCandidatesForstudentsCouncil from "../Notifications/Notification Files/Final list of Eligible Candidates for Students Council Elections 2023-24 DPSRU.pdf";
import DpsruHomeTabsUpdated from "../A tabs creation/DpsruHomeTabsUpdated";
import walkininterviewresulton17 from "../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 17-11-2023.pdf";
import ropeningoffeeportalforoddsem from "../Students/StudentsData/Reopening of fee portal for odd semester.pdf";
import finalspotroundformbaadmission from "../Admissions/newData Of admissions/Final spot round for MBA admission.pdf";
import noticeforprofssagarwaltropyforbptstudents from "../Students/StudentsData/Notice for PROF. S.S. AGRAWAL TROPHY for BPT students.pdf";
import callingsealedquotationsforflowerdecorrr from "../Notifications/Notification Files/WhatsApp Image 2023-12-16 at 8.23.27 AM.jpeg";
import recrutmentofjrffellow from "../Recruitments/Recruitment Data/Advertisement for the Post of Junior Research Fellow (JRF).pdf";
import noticeforrehearsaltimingforcovo21stdecandceremony from "../Notifications/Notification Files/Notice for Rehearsal timings for convocation 21st December and  ceremony instructions for convocation 22nd December 2023.pdf";
import convoAwadiName from "../Notifications/Notification Files/List of Awardee’s for 6th Convocation 2023.pdf";
import weedingoutpolicyofficeorder from "../Notifications/Notification Files/weeding out the old record office order.pdf";
import ReOpeningofoddsemfeeportalnotice from "../Students/StudentsData/Re-Opening of Odd Semester Fee Portal, NOTICE.pdf";
import weedingoutpolicyofficeorder31jan24 from "../Notifications/Notification Files/Weeding out the old record DPSRU-1.pdf";
import reqruitnemrtForJrfdatafeb24 from "../Recruitments/Recruitment Data/Advertisement for the Post of Junior Research Fellow (JRF) in a dST-SERB funded project.pdf";
import AwardpfPHDtomsRUchiTripathi from "../Notifications/Notification Files/Notification for the Award of Ph.D Degree to Ms. Ruchi Tripathi.pdf";
import engagementofaRetiredgovoffhome from "../Notifications/Notification Files/Engagement of a Retired Government Officer on Contract Basis.pdf";
import examformnoticeforprephdstudents from "../Examination/ExaminatonData/Examinations Form Notice for Pre. Ph.D. Students.pdf";
import openingofevensemfeenotice from "../Students/StudentsData/Opening of Even Semester fee Notice.pdf";
import admissioninformationforPGandUGcorses from "../Admissions/newData Of admissions/ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES_new.pdf";
import examFeeNoticeAprmay24 from "../Examination/ExaminatonData/Examination Fees Notice Even Semester Students.pdf";
import ReOpeningofEvensemfeeSem from "../Students/StudentsData/Re-opening of Even Semester Fee Semester.pdf";
import Ufmmeetiondecision from "../Examination/ExaminatonData/UFM Meeting Decision.pdf";
import noticeforsubmissionofsessionalmarkofdhpahrm from "../Notifications/Notification Files/Notice for submission of sessional Marks of D.Pharm. and Pre-PhD Examination.pdf";
import icmrprojectresearchscientisnosnsme from "../Notifications/Notification Files/Applications are invited for the post of ICMR-Project Research Scientist - I Non-Medical Last date May 27, 2024.pdf";
import faqofadminssioa from "../Admissions/newData Of admissions/Admission related FAQs.pdf";
import PhdvivavocaNotificationofmsritutiwari from "./HomePageData/Ph.D Viva-Voce Notification of Ms. Ritu Tiwari.pdf";
import regardingObcNoncreamyadmission2425 from "../Admissions/newData Of admissions/REGARDING OBC NON CREAMY ADMISSIONS 2024-24.pdf";
// import revisedAdmissionScheduleofUgCourses2425 from '../Admissions/newData Of admissions/REVISED ADMISSION SCHEDULE OF UG COURSES 2024-25.pdf'
import newRevisedAdmissionSchedule from "../Admissions/newData Of admissions/New Revised Admission Scedule.pdf";
import Feenoticeforevensemesterexam from "../Notifications/Notification Files/Fees Notice for Even Semester Examination.pdf";
import ExtensionofRegistrationdateforugcourses from "../Admissions/newData Of admissions/Extension of registration date for UG Courses.pdf";
import rollingadvofrguestfac from "../Recruitments/Recruitment Data/Rolling Adv..pdf";
import advertismentforhtepostofjrfinsdstserbfundedpro from "../Recruitments/Recruitment Data/Advertisement for the post of JRF in DST-SERB funded project.pdf";
import vacantpositionofseatsforvariousundergradutecoursesforadmission2425 from "../Admissions/newData Of admissions/Vacant position of seats for various undergraduate courses for admission 2024-25.pdf";
import cumulativereportofAdmissionforvariousundergradutecoursetill11jul from "../Admissions/newData Of admissions/Cumulative report of admissions for various undergraduate courses till 11-07-2024 up to the merit number 600 (Delhi General).pdf";
import cumulativereportofadmissiotill12juluptono900 from "../Admissions/newData Of admissions/Cumulative report of admissions till  12-07-2024 up to the merit number 900.pdf";
import cumulativereportofasdmission13724 from "../Admissions/newData Of admissions/cumulative report of admission 13.07.2024.pdf";
import AdmissionSchedyleforpwdCandidates from "../Admissions/newData Of admissions/Admission schedule for PWD candidates.pdf";
import cumutivereportofadvacsheet18jul24 from "../Admissions/newData Of admissions/Cumulative report of admissions vacant seats in UG Courses.pdf";
import RedesignTakeATour from "../TakeATour/RedesignTakeATour";
import extensionfolastdatforpgandlateralentry from "../Admissions/newData Of admissions/Extension of Last Date for P.G. and Lateral Entry.pdf";
import conrrigendumtorecruimentnoitcenodpsrugf20241 from "../Recruitments/Recruitment Data/Corrigendum to Recruitment Notice No. DPSRU-GF-2024-01.pdf";
import rollingadvforguestvisitonfacpositioninphy from "../Recruitments/Recruitment Data/ROLLING ADVERTISEMENT FOR GUEST-VISITING FACULTY POSITIONS IN PHYSIOTHERAPY.pdf";
import redwhedulingofbpharmleadmission9aug from '../Admissions/newData Of admissions/Rescheduling of B.Pharm LE. Admissions.pdf'
import ugadmissionschedulefo2ndroundofcouns2425 from "../Admissions/newData Of admissions/UG admuission schedule for 2nd round of counselling 2024-25.pdf";
import vaccanciesinugcoursesfrthe2ndroundcouns2425 from "../Admissions/newData Of admissions/vaccancies in UG courses for the 2nd round of counselling 2024-25.pdf";
import AdmissionScheduleforMPT from '../Admissions/newData Of admissions/Admission schedule for MPT.pdf'
import AdmissionScheduleforMba from '../Admissions/newData Of admissions/Admission Schedule for MBA.pdf'
import slidingchagingofcoursebranchinUGProgramme from '../Admissions/newData Of admissions/Sliding Changing of Course Branch in UG Programme pdf.pdf'
import nd2roundcousellingormptprogrammesallbrances from '../Admissions/newData Of admissions/2nd Round counselling for MPT Programmes (All Branches).pdf'
import BanSharpManja from '../Students/StudentsData/Ban Sharp Manjha.pdf'
import ofrderFCDpsru from '../Notifications/Notification Files/Order-FC-DPSRU (3).pdf'
import MpharmAdmissionschedule202425 from '../Admissions/newData Of admissions/M. Pharm Admission Schedule 2024-25.pdf'
import pgcoursemeritlistno01mpharmac from '../Admissions/PG courses Merit list/Provisional Merit List Of M.Pharm courses - [PG Course] - Non-DPSRU - Delhi.pdf'
import pgcoursemeritlistno01mpharmDelhi from '../Admissions/PG courses Merit list/Provisional Merit List Of All M.Pharm courses - [PG Course] - DPSRU - Delhi.pdf'
import pgcoursesmoharnondelhi from '../Admissions/PG courses Merit list/Merit list for M.Pharm Non Delhi.pdf'



import commonmeritlistforMphDelhinonDOSRU2425 from '../Admissions/PG courses Merit list/Common Merit List for MPH Delhi NON DPSRU 2024-25.pdf'


import commonmeritlistmphdelhimphdehodpsru2425 from '../Admissions/PG courses Merit list/Common Merit List for MPH Delhi DPSRU 2024-25.pdf'


import commonMeritlistforMPHnondelhi from '../Admissions/PG courses Merit list/Common merit List for MPH NON Delhi pdf.pdf'

import scheduleforpgAdmissionforMBAmph from '../Admissions/newData Of admissions/schedule for PG Admission for MBA (MPH).pdf'
import schedulefor2roundformbapmitggg from '../Admissions/newData Of admissions/Schedule for 2nd round for MBA (PM, ITM & HHM).pdf'
import walkinintervidevisitionmedicafac from '../Recruitments/Recruitment Data/Walk in interview for visiting Medical Non-Medical school of physiotheauphy .pdf'

import AdmissionScheduleof2ndroundcounsellingforBSCss from "../Admissions/newData Of admissions/ADMISSION SCHEDULE Of  2nd Round Counselling For B.SC (SPORTS SCIENCE).pdf";

import commonMeritlistforexecutivempharmDra2425 from "../Admissions/newData Of admissions/Common Merit List for Executive M Pharm (DRA) 2024 25.pdf";

import SchedulecommonmeritListforDra2425 from "../Admissions/newData Of admissions/Schedule Common Merit List for Executive M. Pharm (DRA) 2024 25.pdf";
import walkininterviewforbpahrmayurveda from '../Recruitments/Recruitment Data/Walk in interview for B.Pharm ( Ayurveda).pdf'
import noticeforMpharmexedra24 from '../Admissions/newData Of admissions/Notice for M. Pharm Exe. (DRA) pdf.pdf'
import schedule2ndroundCOunsellingforMpharm from '../Admissions/newData Of admissions/Schedule  2nd Round of Counselling for M.Pharm.pdf'
import vacanceyavailablefor2ndroundofcounsellformpharm from '../Admissions/newData Of admissions/vacancy available for 2nd round of counselling for M.Pharm.pdf'
import nspPmuspnotice from '../Academics/Scholorships/ScholorShip data/NSP PM-USP Notice.jpg'
import nspPmuspGuideline from '../Academics/Scholorships/ScholorShip data/PM-USP Guidelines.pdf'
import specialAdmissionforvariousugouses from '../Admissions/newData Of admissions/Special Admission Drive for various UG courses  (1).pdf'
import nonDelhiadmissionspeicaldrive from '../Admissions/newData Of admissions/Non Delhi Admissions speical drive pdf.pdf'
import RegardinginspctionofServicebokaspersr202 from '../Notifications/Notification Files/Regarding inspection of service book as per SR 202.jpg'
import secondcounofBpharmlateralentry from '../Admissions/newData Of admissions/SECOND COUNSELING of B.PHARM (LATERAL ENTRY).pdf'
import BpharmLateralEntryAdmissioninDpsugencat from "../Admissions/newData Of admissions/B.Pharm. (Lateral Entry) Admission in DPSRU, General Category pdf.pdf";
import OddSemFeeNotice24 from '../Students/StudentsData/ODD SEMESTER FEE NOTICE 2024.pdf'
import advertforAccountbranchofDPSRU from '../Recruitments/Recruitment Data/Advertisment for Accounts Branch of DPSRU.pdf'
import SpecialAdmissionDriveForDiplomaugandpgCourses from '../Admissions/newData Of admissions/Special Admission Drive for Diploma, UG & PG Courses.pdf'
import sportAdmissionDriveINformation from '../Admissions/newData Of admissions/SPOT ADMISSION DRIVE INFORMATION.pdf'
// import advt28oct24 from '../Recruitments/Recruitment Data/Advt oct24..pdf'
// import advt28oct24 from '../Recruitments/Recruitment Data/Advertisement for NTS 2024.pdf'
// import advt28oct24 from '../Recruitments/Recruitment Data/Advertisement for NTS 2024 - Copy.pdf'
import advt30oct24 from '../Recruitments/Recruitment Data/Advt. Latest.pdf'
import FourththListofBoysHostel from '../Students/HostelData/4th list of Boys hostel.pdf'
import FourththListofGirlssHostel from '../Students/HostelData/Girls hostel fy24.pdf'


// End

function Home() {
  const marqueeRef = useRef(null);

  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };

  const handleMouseOut = () => {
    marqueeRef.current.start();
  };

  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  return (
    <>
      {/* <HomePageNotice/> */}
      {/* this is full container of marquee notice of home page */}

      {/* <RedesignTakeATour /> */}
      <TakeATour />

      <div className="home-full-component">
        <div className="mission-vision-about-us-and-vision-for-viksit-bharat-contaier">
          <div className="mission-vision-and-aboutus-container-home-page">
            <div className="about-us-home-page-container">
              <h2 className="mission-heading">ABOUT US</h2> <hr />
              <p className="mission-text">
                Delhi Pharmaceutical Sciences and Research University (DPSRU) is
                the first Pharmacy University in India and third in the world,
                established by Act 07 of 2008 State legislature of Delhi and
                functional from 2015. It is 100% grant-in-aid State Government
                University recognized under 2f and Section 22 of the UGC. The
                University has the legacy of 57 years starting with the D.
                Pharm. in polytechnic in 1964 to College of Pharmacy in 1972 and
                then the institute Delhi Institute of Pharmaceutical Sciences
                and Research (DIPSAR) in 2004 that got converted into DPSRU as
                it stands now. The Act, Statutes and Ordinances published by the
                Government of Delhi are being implemented and the administration
                is mentored by the General Council with Hon.’ble Lt. Governor as
                the Chancellor, the Board of Governors, the Academic Council and
                the Finance Committee having external distinguished
                personalities as the members.
              </p>
              <NavLink
                className="read-more-vc-message read-more-btn-for-about-us-home-page"
                to="/aboutUs"
              >
                Read More...
              </NavLink>
            </div>{" "}
            <hr className="hr-partition-for-about-and-mission-container" />
            <div className="mission-and-vission-container">
              <div className="mission-container">
                <h3 className="mission-heading">MISSION</h3>
                <hr />
                <p className="mission-text">
                  “To develop core competency in pharmaceutical sector and
                  contribute in building leaders, administrators and personnel
                  who can take up responsibilities as pharmaceutical science
                  professionals, appropriate for community, institutions and
                  industries on health and management related fields”.
                </p>
              </div>

              <div className="vission-container">
                <h3 className="vission-heading mission-heading">VISION</h3>{" "}
                <hr />
                <p className="vision-text mission-text">
                  “To be the ultimate destination for education, training and
                  research in pharmaceutical sciences and allied areas and
                  thereby, cater to the health needs of the people at large”
                </p>
              </div>
              <NavLink
                className="read-more-vc-message read-more-btn-for-about-us-home-page"
                to="/visionMissionObjectives&QualityPolicy"
              >
                Read More...
              </NavLink>
            </div>
          </div>

          <div className="vision-for-viksit-bharat-home-page-container">
            <h2 className="mission-heading">Vision for Viksit Bharat @2047</h2>{" "}
            <hr />
            <p className="mission-text">
              {" "}
              <b>The Indian Pharmaceuticals industry</b> has emerged globally as
              one of the fastest growing industries. India is ranked 3rd
              worldwide in the production and 13th in terms of value with over
              60,000 generic drugs in different 60 therapeutic categories and
              about 60% of the global demand for vaccines The pharma export was
              $25.3 billion in the 2022-2023 financial year. It is valued to be
              approximately as US$45 billion in 2023 and is estimated to reach
              $130 billion by 2030. India It is also emerging as the world’s
              iconic centre for Traditional Medicine and even medical devices
              <br />
              Education plays an important role as mentioned by Hon.’ble Prime
              Minister Shri Narendra Modi about starting of L. M. College of
              Pharmacy in 1947 in Ahmedabad. In Delhi we have Delhi
              Pharmaceutical Sciences & Research University as the first and
              only Pharmacy University of India and third in the world’s
              dedicated to pharmaceutical and allied health sciences. DPSRU is
              thriving to create the state-of-the-art infrastructure research
              facilities to see that it contributes to the Vision of Viksit
              Bharat 2047.
            </p>
            <NavLink
              className="read-more-vc-message read-more-btn-for-about-us-home-page"
              to="/VisionforViksitBharat@2047"
              style={{ width: "15%" }}
            >
              Read More...
            </NavLink>
          </div>
        </div>

        <br />
        {/* <p className="bulletain-text">Recent Notification</p> */}
        <div className="marquee-bulletin-area">
          {/* Working now  */}
          <marquee
            ref={marqueeRef}
            behavior="scroll"
            direction="left"
            className="marquee-notification"
            loop=""
            Scrollamount="7"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div>
              {/* ****************************************** */}
           
              {/* ****************************************** */}
              <a href={advt30oct24}>
                <span> Advertisement No. DPSRU/NTS/OCT/2024</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href='https://dpsrurac.ucanapply.com/recruitment/public/secure?app_id=UElZMDAwMDAxOA=='>
                <span> Application Link for Non – Teaching Posts advertised vide Recruitment Notice No. DPSRU/NTS/OCT/2024</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={FourththListofGirlssHostel}>
                <span> Girls Hostel Accommodation notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={FourththListofBoysHostel}>
                <span> 4th List of Boys hostel Accommodation FY 2024-25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              
              <a href={sportAdmissionDriveINformation}>
                <span>SPOT ADMISSION DRIVE INFORMATION</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={SpecialAdmissionDriveForDiplomaugandpgCourses}>
                <span>Special Admission Drive for Diploma, UG & PG Courses</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={advertforAccountbranchofDPSRU}>
                <span>ENGAGEMENT OF A RETIRED GOVERNMENT OFFICER AS A CONSULTANT/ADVISOR/EXPERT ON CONTRACT BASIS</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={OddSemFeeNotice24}>
                <span> ODD SEMESTER FEE NOTICE 2024</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={BpharmLateralEntryAdmissioninDpsugencat}>
                <span> B.Pharm. (Lateral Entry) Admission in DPSRU, General Category</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={secondcounofBpharmlateralentry}>
                <span> SECOND COUNSELING of B.PHARM (LATERAL ENTRY)</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={RegardinginspctionofServicebokaspersr202}>
                <span> Inspection of service book as per SR 202</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={nonDelhiadmissionspeicaldrive}>
                <span>Non Delhi Admissions special drive</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={specialAdmissionforvariousugouses}>
                <span>Special Admission Drive for various UG courses</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={nspPmuspnotice}>
                <span>NSP PM-USP Notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={nspPmuspGuideline}>
                <span>PM-USP Guidelines</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={vacanceyavailablefor2ndroundofcounsellformpharm}>
                <span> Vacancy available for 2nd round of counselling for M.Pharm</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={schedule2ndroundCOunsellingforMpharm}>
                <span> Schedule 2nd Round of Counselling for M.Pharm</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={noticeforMpharmexedra24}>
                <span> Cancellation notification of the admissions in respect of executive M. Pharm admissions scheduled on 17/9/2024</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href="https://forms.gle/JNWVaEAfy2ArHF1x6">
                <span> Student's I-Card Google Form</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={walkininterviewforbpahrmayurveda}>
                <span>Walk in interview for visiting faculty for B.Pharm ( Ayurveda)</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={SchedulecommonmeritListforDra2425}>
                <span>Schedule Common Merit List for Executive M. Pharm (DRA) 2024 25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={commonMeritlistforexecutivempharmDra2425}>
                <span>Common Merit List for Executive M Pharm (DRA) 2024 25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={AdmissionScheduleof2ndroundcounsellingforBSCss}>
                <span>ADMISSION SCHEDULE Of  2nd Round Counselling For B.SC (SPORTS SCIENCE)</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={walkinintervidevisitionmedicafac}>
                <span>Walk-in interview for visiting faculty in the School of Physiotherapy</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={schedulefor2roundformbapmitggg}>
                <span>Schedule for 2nd round for MBA (PM, ITM & HHM)</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={scheduleforpgAdmissionforMBAmph}>
                <span>schedule for PG Admission for MBA (MPH)</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={commonMeritlistforMPHnondelhi}>
                <span>Common merit List for MPH NON Delhi</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={commonmeritlistmphdelhimphdehodpsru2425}>
                <span>Common Merit List for MPH Delhi DPSRU 2024-25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={commonmeritlistforMphDelhinonDOSRU2425}>
                <span>Common Merit List for MPH Delhi NON DPSRU 2024-25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={pgcoursesmoharnondelhi}>
                <span>Provisional Merit List Of  M.Pharm courses - [PG Course] - Non  Delhi</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={pgcoursemeritlistno01mpharmDelhi}>
                <span>Provisional Merit List Of All M.Pharm courses - [PG Course] - DPSRU - Delhi</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={pgcoursemeritlistno01mpharmac}>
                <span>Provisional Merit List Of M.Pharm courses - [PG Course] - Non-DPSRU - Delhi</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={MpharmAdmissionschedule202425}>
                <span>M. Pharm Admission Schedule 2024-25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={ofrderFCDpsru}>
                <span>Order-FC-DPSRU</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
            
              <a href={BanSharpManja}>
                <span>Ban Sharp Manjha</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              
              <a href={nd2roundcousellingormptprogrammesallbrances}>
                <span>2nd Round counselling for MPT Programmes (All Branches)</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>

              <a href={AdmissionScheduleforMPT}>
                <span>Admission schedule for MPT</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={AdmissionScheduleforMba}>
                <span>Admission schedule for MBA</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={slidingchagingofcoursebranchinUGProgramme}>
                <span>Sliding Changing of Course Branch in UG Programme </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>

              <a href={ugadmissionschedulefo2ndroundofcouns2425}>
                <span>UG admission schedule for 2nd round of counselling 2024-25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>

              <a href={vaccanciesinugcoursesfrthe2ndroundcouns2425}>
                <span>Vaccancies in UG courses for the 2nd round of counselling 2024-25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>


              <a href={redwhedulingofbpharmleadmission9aug}>
                <span>Rescheduling of B.Pharm LE. Admissions</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeE2QdMBuHEdxr36hge4YCaNYZ7fTe7Dy85T4mEsiqdpyaUVg/viewform">
                <span>Internet Users Account Creation Form</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={rollingadvforguestvisitonfacpositioninphy}>
                <span>
                  ROLLING ADVERTISEMENT FOR GUEST/VISITING FACULTY POSITIONS IN
                  PHYSIOTHERAPY
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={conrrigendumtorecruimentnoitcenodpsrugf20241}>
                <span>
                  Corrigendum to Recruitment Notice No. DPSRU/GF/2024/01
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
             
              <a href={extensionfolastdatforpgandlateralentry}>
                <span>Extension of Last Date for P.G. and Lateral Entry</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={cumutivereportofadvacsheet18jul24}>
                <span>
                  Cumulative report of admissions/vacant seats in UG Courses as
                  on 18-07-2024
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={AdmissionSchedyleforpwdCandidates}>
                <span>Admission schedule for PWD candidates</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={cumulativereportofasdmission13724}>
                <span>
                  Cumulative report of admissions for various undergraduate
                  courses till 13-07-2024 up to the merit number 1200 (Delhi
                  General)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <a>
                <span>
                  Admissions schedule of the Kashmiri migrants scheduled to be
                  on 20th, July 2024
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={cumulativereportofadmissiotill12juluptono900}>
                <span>
                  Cumulative report of admissions till 12-07-2024 up to the
                  merit number 900
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  cumulativereportofAdmissionforvariousundergradutecoursetill11jul
                }
              >
                <span>
                  Cumulative report of admissions for various undergraduate
                  courses till 11-07-2024 up to the merit number 600 (Delhi
                  General)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  vacantpositionofseatsforvariousundergradutecoursesforadmission2425
                }
              >
                <span>
                  Vacant position of seats for various undergraduate courses for
                  admission 2024-25
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newRevisedAdmissionSchedule}>
                <span>New revised admission Schedule 2024-25</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={advertismentforhtepostofjrfinsdstserbfundedpro}>
                <span>
                  Advertisement for the post of JRF in DST-SERB funded project
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={rollingadvofrguestfac}>
                <span>
                  ROLLING ADVERTISEMENT FOR GUEST/VISITING FACULTY POSITIONS
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ExtensionofRegistrationdateforugcourses}>
                <span>Extension of registration date for UG Courses</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Feenoticeforevensemesterexam}>
                <span>Fees Notice for Even Semester Examination</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={regardingObcNoncreamyadmission2425}>
                <span>REGARDING OBC NON CREAMY ADMISSIONS 2024-24</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={PhdvivavocaNotificationofmsritutiwari}>
                <span>Ph.D Viva-Voce Notification of Ms. Ritu Tiwari</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={faqofadminssioa}>
                <span>Admission related FAQs</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={icmrprojectresearchscientisnosnsme}>
                <span>
                  Applications are invited for the post of ICMR-Project Research
                  Scientist - I (Non-Medical)" (Last date: May 27, 2024)"
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={noticeforsubmissionofsessionalmarkofdhpahrm}>
                <span>
                  Notice for submission of sessional Marks of D.Pharm. and
                  Pre-PhD Examination
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Ufmmeetiondecision}>
                <span>UFM Meeting Decision</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ReOpeningofEvensemfeeSem}>
                <span>Re-opening of Even Semester Fee Semester</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://dpsruonline.in/">
                <span>
                  Click here for Admissions to UG & PG Courses for the year
                  2024-25
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={examFeeNoticeAprmay24}>
                <span>Examination Fees Notice Even Semester Students</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={admissioninformationforPGandUGcorses}>
                <span>
                  ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={openingofevensemfeenotice}>
                <span>Opening of Even Semester fee Notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={examformnoticeforprephdstudents}>
                <span>Examinations Form Notice for Pre. Ph.D. Students</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={engagementofaRetiredgovoffhome}>
                <span>
                  Engagement of a Retired Government Officer on Contract Basis
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={AwardpfPHDtomsRUchiTripathi}>
                <span>
                  Notification for the Award of Ph.D Degree to Ms. Ruchi
                  Tripathi
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a>
                <span>
                  {/* Admissions for the academic session 2024-25 in Diploma, UG,
                  and PG courses, EXCEPT M. Pharm. and B. Pharm. (LE), will be
                  conducted through CUET. Only candidates who have successfully
                  passed CUET will be considered, and the merit list will be
                  prepared based on the CUET-24 scores. */}
                  The admission for "All the UG Courses during this Academic
                  Year 2024-25, Will be held on the basis of merit at the
                  qualifying examination and the admissions for PG courses will
                  be held through CUET"
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={reqruitnemrtForJrfdatafeb24}>
                <span>
                  Advertisement for the Post of Junior Research Fellow (JRF) in
                  a dST-SERB funded project
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={weedingoutpolicyofficeorder31jan24}>
                <span>Weeding out the old record DPSRU-1</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ReOpeningofoddsemfeeportalnotice}>
                <span>Re-Opening of Odd Semester Fee Portal, NOTICE</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={weedingoutpolicyofficeorder}>
                <span>Weeding out the old record - Office order</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://www.youtube.com/live/ukSLiTOVma0?feature=shared">
                <span>Alumni Meet 22 December 2023</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://youtube.com/live/bdCky_0o9lw?feature=share">
                <span>Dpsru 6th Convocation Ceremony</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://auth.mygov.in/user/login?destination=oauth2/authorize">
                <span>Share your ideas for a Viksit Bharat</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={convoAwadiName}>
                <span>List of Awardee’s for 6th Convocation 2023</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={noticeforrehearsaltimingforcovo21stdecandceremony}>
                <span>
                  Notice for Rehearsal timings for convocation on 21st December
                  and ceremony instructions for convocation on 22nd December
                  2023
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={recrutmentofjrffellow}>
                <span>
                  Advertisement for the Post of Junior Research Fellow (JRF)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={callingsealedquotationsforflowerdecorrr}>
                <span>
                  Calling sealed quotations for flower decorations at 6th
                  convocation in DPSRU
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={noticeforprofssagarwaltropyforbptstudents}>
                <span>
                  Notice for PROF. S.S. AGRAWAL TROPHY for BPT students
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={finalspotroundformbaadmission}>
                <span>Final spot round for MBA admission</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ropeningoffeeportalforoddsem}>
                <span>Reopening of fee portal for odd semester</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={walkininterviewresulton17}>
                <span>
                  Result of Walk-in-Interview for Guest Faculties held on
                  17/11/2023
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={finalListOfEligibleCandidatesForstudentsCouncil}>
                <span>
                  Final list of Eligible Candidates for Students Council
                  Elections 2023-24 DPSRU
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={walkinInterviewResult}>
                <span>
                  Result of Walk-in-Interview for Guest Faculties held on
                  03/11/2023
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={walkinInterviewOn17Nov}>
                <span>
                  Walk-in Interview for Guest Faculties of Pharmacy and
                  Physiotherapy on 17/11/2023 (Reporting Time strictly from
                  10:00 AM to 10:30 AM)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={convoForm6th}>
                <span>6th convocation registration form 2023</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={CircularForTakinganOrganistionalOath}>
                <span>
                  Observance of Vigilance Awareness Week 2023 in DPSRU from 30
                  Oct to 05 Nov 2023 (Circular for Organizational Oath)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={circularForTakingIntegrityPledge}>
                <span>Circular for taking integrity pledge Individually</span>
              </a>{" "}
              ||
              <a href="https://pledge.cvc.nic.in/" style={{ color: "#000" }}>
                <span>Click here..</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={UFMNotice}>
                <span>UFM Notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={oddSemFeeNotice}>
                <span>Odd semester fee notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={NotificationPhdDegreeMsNeha}>
                <span>
                  Notification for the Award of Ph.D Degree to Ms. Neha
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={NotificationPhdDegreeMsSweta}>
                <span>
                  Notification for the Award of Ph.D Degree to Ms. Shweta Paroha
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={walkinInterview3010}>
                <span>
                  "Walk-in- Interview for Guest Faculties on 03/11/2023
                  (Reporting Time strictly from 10:00 AM to 11:00 AM)
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://dpsruonline.in/">
                {" "}
                <span>
                  Admission open UG Courses Final Spot Round -{" "}
                  <b style={{ color: "black" }}>Apply Here</b>
                </span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={convoFiles}>
                <span>6th Convocation Notice</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={phdCourseWork}>
                <span>Pre PhD course work</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata001}>
                <span>Ph.D Viva-Voce Notification of Ms. Shweta Paroha</span>
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata002}>
                <span>
                  Notification for the Award of Ph.D Degree to Ms. Meenu Grover
                  Sharma
                </span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata003}>
                <span>
                  Date extended Notice for Final Admission Pre-PhD 2023-24
                </span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata004}>
                <span>
                  List of selected candidates for the Final Admissions to
                  Pre-PhD 2023-24{" "}
                </span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata005}>
                <span>Notice for final admission Pre-PhD 2023-24 </span>
              </a>
              <img src={newGif} alt="new" />
              <a href={newdata006}>
                <span>Result of PhD Entrance Examination 2023-24</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata007}>
                <span>
                  Notice and List of candidates of Pre-PhD Interview, September
                  2023
                </span>
                <img src={newGif} alt="new" />
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata008}>
                <span>List of Approved Supervisors, September 2023</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata009}>
                <span>Result of MPT entrance exam. 2023</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={newdata010}>
                <span>
                  Notice and Revised list of Candidates for Pre-PhD Entrance
                  test{" "}
                </span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://dpsruonline.in/">
                <span>Special Spot Round for UG courses : Apply Here</span>
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              {/* ****************************************** */}
              <a href={vacantSeatforSpecial}>
                Vacant Seats for Special Spot Round Starting from 25/8/2023
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={specialSpotRoundC}>
                Special spot round for UG courses (Fresh candidates can also
                apply) will start from 25/08/23{" "}
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={ReOpeningOfSemester}>
                Reopening of semester fee with late fine
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={PhdVivaVocaNotiMsMeenuG}>
                PhD Viva-Voce Notification of Ms. Meenu Grover Sharma{" "}
              </a>{" "}
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Circular4823}>Circular </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={DocVerificationNotice}>
                1st Notice regarding document verification at University for UG
                Courses{" "}
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  NoticeforextensionoftheonlineapplicationdeadlinefortheBPharmLEandPGcourses
                }
              >
                Notice for extension of the online application deadline for the
                B. Pharm LE and PG courses
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Circular02}> Circular</a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={YogaAndFitness}>
                Yoga and Fitness center Admission Notice{" "}
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Firstroundonlinecounsellingschedule202}>
                First round online counselling schedule-2023
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href="https://dpsruonline.in/">
                To apply for B. Pharm. (LE) and PG courses: Click here (Last
                date 10/08/23)
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  RevisedProvisionalMeritListsofDelhicandidatesforUGcourses2023
                }
              >
                Provisional Merit Lists of Delhi candidates for UG courses-2023{" "}
              </a>
              <img src={newGif} alt="new" />
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  RevisedProvisionalMeritListsofNONDelhicandidatesforUGcourses2023
                }
              >
                Provisional Merit Lists of Non-Delhi candidates for UG
                courses-2023{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a
                href={
                  AlphabeticalListofcandidateswhohavenotfilledcoursePreferences
                }
              >
                Alphabetical List of candidates who have not filled course
                Preference
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Commoninstructionsforskilltestfornonteachingposts}>
                Common instructions for skill test for non-teaching posts{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={NoticeregardingUGcoursespreferencefilling202324}>
                Notice regarding UG courses preference filling 2023-24{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={NOTICEofSkillTest}>
                Schedule of Skill Test for recruitment of Non-Teaching Posts
                (Group ‘B’ ‘C’ Ministerial Technical){" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Document570}>Circular</a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={EmployementNotice}>Employment Notice</a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Prospectus2023forweb}>Prospectus 2023 </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={AdvertisementofJRF}>
                “Advertisement for the Post of CCRUM – JRF”{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={Advertisement2023241}>
                “Announcement of Admissions (2023-24)”{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={PhdAdmission202324}>Notice for PhD Admission </a>
              <span className="devider-of-marquee-noti-home"> || </span>
              <a href={CorrigendumofNTS1}>
                Extension of dates of uploading of Documents for Group B C Post
                and Corrigendum of Junior Stenographer{" "}
              </a>
              <span className="devider-of-marquee-noti-home"> || </span>
            </div>
          </marquee>

          <Link
            className="see-all-notification-in-marquee-tab"
            to="/notifications"
          >
            All Notifications
          </Link>
        </div>

        <div className="home-component">
          <div className="sidenav-and-chancellor-flex-box-new">
            {/* Side nav data of mar03 2023 */}
            <div className="responsive-home-side-nav-for-small-screen">
              {
                // sidenav open toggle
                isSideNavOpen ? (
                  <div
                    className="sidenav-comp-home"
                    style={{ overflow: "hidden", zIndex: "999" }}
                  >
                    <button
                      onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                      className="sidenav-responsive-button"
                    >
                      <FaRegWindowClose />
                    </button>

                    {SidebarData.map((item, index) => {
                      return (
                        <li key={index} className={item.cName}>
                          <Link to={item.path}>
                            {item.icon}
                            <span>{item.title}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <button
                      onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                      className="sidenav-responsive-button"
                    >
                      <FaListAlt style={{ background: "none" }} />
                    </button>

                    <div
                      className="sidenav-comp-home-display-none"
                      style={{ display: "none" }}
                    >
                      {SidebarData.map((item, index) => {
                        return (
                          <li key={index} className={item.cName}>
                            <Link to={item.path}>
                              {item.icon}
                              <span>{item.title}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </div>
                  </>
                )
              }
              {/* // sidenav open toggle end here */}
            </div>

            {/* Side nav big screen codes */}
            <div
              className="responsive-home-side-nav-for-big-screen"
              style={{
                background: " var(--primaryBlue)",
                width: "45%",
                paddingTop: "2%",
              }}
            >
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
            </div>

            <div className="upper-cont-comp">
              {/* vc area */}
              <div className="vc-container">
                <h4 className="img-heading">Chancellor Speaks</h4>
                <div className="vc-container-data">
                  <div className="photos-comp">
                    <img
                      src={chanslor}
                      alt="Vice Chancellor"
                      className="vc-img"
                    />
                    <h3 className="vc-intro">
                    Sh. Vinai Kumar Saxena<br />
                    Hon’ble Lt. Governor, Delhi
                      <br />
                      Chancellor, DPSRU
                    </h3>
                  </div>
                  <div className="vc-text-area">
                    <p className="vc-message">
                      Pharmaceutical Science is a dynamic and rapidly changing
                      field that combines a board range of scientific
                      disciplines critical to the discovery and development of
                      new drugs and therapies. The pharmaceutical sector has
                      been a key asset in the international pursuit of health
                      for humankind. A qualification in this discipline can lead
                      to a variety of job roles, Which often offer good
                      professional progression for research and management in
                      allied health sciences.
                      <br />
                      In last 6 Years the University has been working hard to
                      attain high standards of academic excellence, particuarly
                      in the field of research, innovation and incubation. The
                      Univesity's contribution in providing skilled and
                      specialized human resouce for the healthcare system is
                      widely recognized.
                    </p> <br />
                    <Link
                      to="/chancellorDpsru"
                      className="read-more-vc-message"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              {/* vc area end here*/}

              {/* Chancellor container start here */}
              {/* going to comment this details of old vc of Dpsru Dr mahesh verma */}
              {/* <div className="chancellor-container">
                <h4 className="img-heading">Vice-Chancellor</h4>

                <div className="chancellor-container-data vc-container-data">
                  <div className="photos-comp">
                    <img
                      src={profVRavichandiran}
                      alt="Vice Chancellor"
                      className="vc-img"
                    />
                    <h3 className="vc-intro">
                      Prof. (Dr.) Mahesh Verma <br />
                      Vice Chancellor
                    </h3>
                  </div>

                  <div className="vc-text-area">
                    <p className="vc-message">
                      Padma Shri Awardee <br />
                      National Science and Technology Awardee <br />
                      DR. B. C. Roy Awardee <br />
                      BDS, MDS, MBA, FAMS, FDSRCS (England), FDSRCPSG (Glasgow),
                      FDSRCS <br />
                      <br />
                    </p>{" "}
                    <Link
                      to="/vice-chancellor-dpsru"
                      className="read-more-vc-message"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div> */}
              <div className="chancellor-container">
                <h4 className="img-heading">Vice-Chancellor</h4>

                <div className="chancellor-container-data vc-container-data">
                  <div className="photos-comp">
                    <img
                      src={profVRavichandiran}
                      alt="Vice Chancellor"
                      className="vc-img"
                    />
                    <h3 className="vc-intro">
                    Prof. Ravichandiran V.<br />
                      Vice Chancellor
                    </h3>
                  </div>

                  <div className="vc-text-area">
                    <p className="vc-message">
                    Prof. Ravichandiran V. , Vice Chancellor, Delhi Pharmaceutical Sciences and Research University, New Delhi and Former Founder Director, NIPER Kolkata and Former Director In-charge of NIPER Hajipur having 28 years of teaching, research and administrative experience. His research is focused to utilization of animal models for the evaluation of efficacy of Natural Products and understanding its mechanism of action; development of Pharmaceutical Products from the natural sources and semi-synthetic process development for making affordable medicines. He is passionate about translating the results of his research into products.
                    </p>{" "} <br />
                    <Link
                      to="/vice-chancellor-dpsru"
                      className="read-more-vc-message"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>

              {/* Registrar container start here */}

              <div className="chancellor-container">
                <h4 className="img-heading">Registrar Speaks</h4>

                <div className="chancellor-container-data vc-container-data">
                  <div className="photos-comp">
                    <img
                      src={newregistrarShahooSIr}
                      alt="Registrar"
                      className="vc-img"
                    />
                    <h3 className="vc-intro">
                      Prof. P.K. Sahoo, <br /> Offg. Registrar (DPSRU) &
                      Director (DIPSAR)
                    </h3>
                  </div>

                  <div className="vc-text-area">
                    <p className="vc-message">
                      Delhi Pharmaceutical Sciences and Research University
                      (DPSRU) is offering UG and PG education in the field of
                      Pharmacy, Allied health and Physiotherapy. All the courses
                      are approved by Pharmacy Council of India (PCI) & All
                      India Council for Technical Education (AICTE). The
                      University continuously strives to provide high quality
                      professional education and research training to students
                      with the help of their ever dedicated staff to build one
                      of the best educational setups.
                    </p> <br />
                    <Link
                      to="/registrar-of-dpsru"
                      className="read-more-vc-message"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Registrar container end here */}
          </div>

          {/* DpsruHomeTabs imported here */}
          {/* <DpsruHomeTabs /> */}
          <DpsruHomeTabsUpdated />
          {/* DpsruHomeTabs imported here */}
        </div>
      </div>
    </>
  );
}

export default Home;
