import React, { useState } from "react";
import "./Notifications.css";
import newgif from ".././NEW/new.gif";

import vacantSeatforSpecial from "./Notification Files/Vacant-Seats-for-Special-Spot-Round.pdf";
import specialSpotRoundC from "./Notification Files/SPECIAL-SPOT-ROUND-COUNSELLING-Advertiesment-1.pdf";
import ReOpeningOfSemester from "./Notification Files/CamScanner-08-09-2023-10.49.25_1.pdf";
import PhdVivaVocaNotiMsMeenuG from "./Notification Files/PhD-Viva-Voce-Notification-of-Ms.-Meenu-Grover-Sharma.pdf";
import Circular4823 from "./Notification Files/Circular-4.8.23.pdf";
import DocVerificationNotice from "./Notification Files/document-verification-notice.pdf";
import NoticeforextensionoftheonlineapplicationdeadlinefortheBPharmLEandPGcourses from "./Notification Files/WhatsApp-Image-2023-07-22-at-10.55.13-AM.jpeg";
import Circular02 from "./Notification Files/Circular02.pdf";
import YogaAndFitness from "./Notification Files/YogaAndFitness.pdf";
import Firstroundonlinecounsellingschedule202 from "./Notification Files/First-round-online-counselling-schedule-2023.pdf";
import RevisedProvisionalMeritListsofDelhicandidatesforUGcourses2023 from "./Notification Files/Revised-Provisional-Merit-Lists-of-Delhi-candidates-for-UG-courses-2023.pdf";
import RevisedProvisionalMeritListsofNONDelhicandidatesforUGcourses2023 from "./Notification Files/Revised-Provisional-Merit-Lists-of-Non-Delhi-candidates-for-UG-courses-2023.pdf";
import AlphabeticalListofcandidateswhohavenotfilledcoursePreferences from "./Notification Files/Alphabetical-List-of-candidates-who-have-not-filled-course-Preferences.pdf";
import Commoninstructionsforskilltestfornonteachingposts from "./Notification Files/Common-instructions-for-skill-test-for-non-teaching-posts.pdf";
import NoticeregardingUGcoursespreferencefilling202324 from "./Notification Files/Notice-regarding-UG-courses-preference-filling-2023-24.pdf";
import NOTICEofSkillTest from "./Notification Files/NOTICE-of-Skill-Test.pdf";
import Document570 from "./Notification Files/Document-570.pdf";
import EmployementNotice from "./Notification Files/Employement-Notice.pdf";
import Prospectus2023forweb from "./Notification Files/Prospectus-2023-for-web.pdf";
import AdvertisementofJRF from "./Notification Files/Advertisement-of-JRF.pdf";
import Advertisement2023241 from "./Notification Files/Advertisement-2023-24-1.pdf";
import PhdAdmission202324 from "./Notification Files/Phd-Admission-2023-24.pdf";
import CorrigendumofNTS1 from "./Notification Files/Corrigendum-of-NTS-1.pdf";

// old Notice from  Corrigendum to Recruitment Notice No. DPSRU/NTS/2022/01
import noticeOld38 from "./Notification Files/Corrigendum-to-Advt.-No.-DPSRUNTS202201.pdf";
import noticeOld40 from "./Notification Files/Document-29.pdf";
import noticeOld41 from "./Notification Files/Document-23.pdf";
import noticeOld42 from "./Notification Files/Advt-of-Group-A-for-website-1.pdf";
import noticeOld44 from "./Notification Files/Convocation-Announcement-2-1.pdf";
import noticeOld45 from "./Notification Files/Adobe-Scan-Nov-02-2022.pdf";
import noticeOld49 from "./Notification Files/Adobe-Scan-Nov-02-2022.pdf";
import noticeOld50 from "./Notification Files/Tentative-Date-Of-5th-Convocation-.pdf";
import noticeOld52 from "./Notification Files/Adobe-Scan-26-Oct-2022.pdf";
import noticeOld53 from "./Notification Files/Applications-for-admissions-of-PG-programs-extended-to-29th-August-2022.jpg";
import noticeOld54 from "./Notification Files/Post-Matric-and-Merit-cum-means-Scholarship.jpeg";
import noticeOld55 from "./Notification Files/DPSRU-NIB-Joint-Certificate-Program-on-Quality-Assurance-in-Biopharmaceuticals.jpg";
import noticeOld56 from "./Notification Files/Adobe-Scan-20-Aug-2022-1.pdf";
import noticeOld57 from "./Notification Files/Adobe-Scan-20-Aug-2022.pdf";
import noticeOld58 from "./Notification Files/Document-9.pdf";
import noticeOld59 from "./Notification Files/Notice-for-Ph.D-Viva-voce-of-Mr.-kushagra-Khanna-1.pdf";
import noticeOld60 from "./Notification Files/pre-ph.D-time-table-1.pdf";
import noticeOld61 from "./Notification Files/circular-pre-ph.D-1.pdf";
import noticeOld62 from "./Notification Files/WhatsApp-Image-2022-08-12-at-12.12.30-PM.jpeg";
import noticeOld63 from "./Notification Files/har-ghar-tiranga-2022.jpg";
import noticeOld65 from "./Notification Files/2nd-allotment-list-girls-hostel.pdf";
import noticeOld66 from "./Notification Files/2nd-Allotment-List-Boys-Hostel.pdf";
import noticeOld67 from "./Notification Files/notice-2-gh.pdf";
import noticeOld68 from "./Notification Files/notice-3-gh.pdf";
import noticeOld69 from "./Notification Files/notice-1-GH.pdf";
import noticeOld70 from "./Notification Files/hostel-notice-2.pdf";
import noticeOld71 from "./Notification Files/hostel-notice-3.pdf";
import noticeOld72 from "./Notification Files/hostel-notice-22722.pdf";
import noticeOld73 from "./Notification Files/Hostel-notice.pdf";
import noticeOld74 from "./Notification Files/List.pdf";
import noticeOld75 from "./Notification Files/Notice-annexure-1-2-3.pdf";
import noticeOld76 from "./Notification Files/result-mlt-ayur-dip-ceutics-bba-mba.pdf";
import noticeOld77 from "./Notification Files/website-mba.pdf";
import noticeOld78 from "./Notification Files/List-of-candidates.pdf";
import noticeOld79 from "./Notification Files/Notice-3.pdf";
import noticeOld80 from "./Notification Files/Result-of-Pre-Ph.D.-Entrance-Examination-June-2022-1.pdf";
import noticeOld81 from "./Notification Files/BBA-website-1.pdf";
import noticeOld82 from "./Notification Files/Notification-for-Ph.D-Viva-vice-of-Mr.-Mrityunjay-Singh.pdf";
import noticeOld83 from "./Notification Files/website.pdf";
import noticeOld84 from "./Notification Files/WhatsApp-Image-2022-05-25-at-10.36.56-AM.jpeg";
import noticeOld85 from "./Notification Files/List-of-candidates-of-phd-entrance-exam.pdf";
import noticeOld86 from "./Notification Files/AR-Result.pdf";
import noticeOld87 from "./Notification Files/Walk-In-Interview-Visiting-Faculty.pdf";
import noticeOld88 from "./Notification Files/Eligible-Candidate_AR.pdf";
import noticeOld89 from "./Notification Files/Document-2.pdf";
import noticeOld90 from "./Notification Files/Ph.D.-advertisement-details-for-website-1.pdf";
import noticeOld90b from "./Notification Files/Ph.D-Admission-Form.pdf";
import noticeOld92 from "./Notification Files/General-Instructions-and-Eligibility-Criteria-for-Assistant-Registrar-on-Contract-Basis-1.pdf";
import noticeOld93 from "./Notification Files/General-Instructions-and-Eligibility-Criteria-for-Assistant-Registrar-on-Contract-Basis-1 (1).pdf";
import noticeOld94 from "./Notification Files/corrigendum-Website-1.pdf";
import noticeOld95 from "./Notification Files/Recruitment-Notice-for-Assistant-Professor-Diploma-Pharmacy-1.pdf";
import noticeOld96 from "./Notification Files/Annexure-1-Recruitment-Rules-Diploma-in-Pharmacy-1.pdf";
import noticeOld97 from "./Notification Files/Annexure-2-Self-Declaration-Format-for-OBC-Certificate-1-1.pdf";
import noticeOld98 from "./Notification Files/Faculty-Recruitment-Notice-No.-DPSRU_TEACHING_2022_01.pdf";
import noticeOld99 from "./Notification Files/Annexure-1-Recruitment-Rules.pdf";
import noticeOld100 from "./Notification Files/Annexure-2-Self-Declaration-Format-for-OBC-Certificate.pdf";
import noticeOld101 from "./Notification Files/notice-of-result.pdf";
import noticeOld102 from "./Notification Files/Document-85.pdf";
import noticeOld103 from "./Notification Files/Adobe-Scan-Jan-31-2022.pdf";
import noticeOld104 from "./Notification Files/List-of-Candidates-Shortlisted-for-Interview-for-the-post-of-Assistant-Professor-Pharmaceutics-Regular.pdf";

// 10/10/2023
import newdata001 from "./Notification Files/Ph.D-Viva-Voce-Notification-of-Ms.-Shweta-Paroha.pdf";
import newdata002 from "./Notification Files/Notification-for-the-Award-of-Ph.D-Degree-to-Ms.-Meenu-Grover-Sharma.pdf";
import newdata003 from "./Notification Files/Date-extended-Notice-for-Final-Admission-Pre-PhD-2023-24.pdf";
import newdata004 from "./Notification Files/List-of-final-admission-Pre-PhD-2023-24.pdf";
import newdata005 from "./Notification Files/Notice-for-final-admission-Pre-Ph.D-2023-24.pdf";
import newdata006 from "./Notification Files/Result-of-Ph.D.-Entrance-Examination-2023-24.pdf";
import newdata007 from "./Notification Files/Notice-and-List-for-candidates-of-Pre-PhD-Interview-September-2023.pdf";
import newdata008 from "./Notification Files/List-for-approved-Supervisors-September-2023.pdf";
import newdata009 from "./Notification Files/MPT-Entrance-Result.pdf";
import newdata010 from "./Notification Files/Revised-list.pdf";

import convoNotice from "./Notification Files/Convocation-Notice.pdf";
import phdCourseWork from "./Notification Files/pre-PhD-course-work.pdf";
import walkininterviewguest20oct from './Notification Files/Advertisement.pdf'
import oddSemFeeNotice from '../Students/StudentsData/odd semester fee notice.pdf'


import NotificationPhdDegreeMsSweta from "../Examination/ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Shweta Paroha_rotated.pdf";

import NotificationPhdDegreeMsNeha from "../Examination/ExaminatonData/Notification for the Award of Ph.D Degree to Ms. Neha_rotated.pdf";
import UFMNotice from "../Examination/ExaminatonData/UFM Notice.pdf";



import circularForTakingIntegrityPledge from './Notification Files/Circular for taking integrity pledge.pdf'
import CircularForTakinganOrganistionalOath from './Notification Files/Circular for taking an organisational Oath on 3 November 2023 in DPSRU.pdf'
import convoForm6th from './Notification Files/6th convocation registration form 2023.pdf'
import walkinInterviewOn17Nov from './Notification Files/Interview on 17.11.23.pdf'
import walkinInterviewResult from '../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 03-11-2023.pdf'
import finalListOfEligibleCandidatesForstudentsCouncil from './Notification Files/Final list of Eligible Candidates for Students Council Elections 2023-24 DPSRU.pdf'
import walkininterviewresulton17 from '../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 17-11-2023.pdf'
import reopeningoffeeportalforoddsem from '../Students/StudentsData/Reopening of fee portal for odd semester.pdf'
import finalspotroundformbaadmission from '../Admissions/newData Of admissions/Final spot round for MBA admission.pdf'
import noticeforprofssagarwaltropyforbptstudents from '../Students/StudentsData/Notice for PROF. S.S. AGRAWAL TROPHY for BPT students.pdf'
import callingsealedquotationsforflowerdecorrr  from './Notification Files/WhatsApp Image 2023-12-16 at 8.23.27 AM.jpeg'
import recrutmentofjrffellow from '../Recruitments/Recruitment Data/Advertisement for the Post of Junior Research Fellow (JRF).pdf'
import noticeforrehearsaltimingforcovo21stdecandceremony from './Notification Files/Notice for Rehearsal timings for convocation 21st December and  ceremony instructions for convocation 22nd December 2023.pdf'
import convoAwadiName from './Notification Files/List of Awardee’s for 6th Convocation 2023.pdf'
import weedingoutpolicyofficeorder from './Notification Files/weeding out the old record office order.pdf'
import ReOpeningofoddsemfeeportalnotice from '../Students/StudentsData/Re-Opening of Odd Semester Fee Portal, NOTICE.pdf'
import weedingoutpolicyofficeorder31jan24 from './Notification Files/Weeding out the old record DPSRU-1.pdf'
import centerForprofessionDevinHigherEduc from './Notification Files/Centre for Professional Development in Higher Education (CPDHE).pdf'
import AwardpfPHDtomsRUchiTripathi from './Notification Files/Notification for the Award of Ph.D Degree to Ms. Ruchi Tripathi.pdf'
import engagementofaRetiredgovoff from './Notification Files/Engagement of a Retired Government Officer on Contract Basis.pdf'
import examformnoticeforprephdstudents from '../Examination/ExaminatonData/Examinations Form Notice for Pre. Ph.D. Students.pdf'
import admissioninformationforPGandUGcorses from '../Admissions/newData Of admissions/ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES_new.pdf'
import examFeeNoticeAprmay24 from '../Examination/ExaminatonData/Examination Fees Notice Even Semester Students.pdf'
import noticeforsubmissionofsessionalmarkofdhpahrm from './Notification Files/Notice for submission of sessional Marks of D.Pharm. and Pre-PhD Examination.pdf'
import icmrprojectresearchscientisnosnsme from './Notification Files/Applications are invited for the post of ICMR-Project Research Scientist - I Non-Medical Last date May 27, 2024.pdf'
import ExtensionofRegistrationdateforugcourses from '../Admissions/newData Of admissions/Extension of registration date for UG Courses.pdf'
import rollingadvofrguestfac from '../Recruitments/Recruitment Data/Rolling Adv..pdf'
import newRevisedAdmissionSchedule from '../Admissions/newData Of admissions/New Revised Admission Scedule.pdf'
import ofrderFCDpsru from './Notification Files/Order-FC-DPSRU (3).pdf'
// import Feenoticeforevensemesterexam from './Notification Files/Fees Notice for Even Semester Examination.pdf'
import walkinintervidevisitionmedicafac from '../Recruitments/Recruitment Data/Walk in interview for visiting Medical Non-Medical school of physiotheauphy .pdf'
import nspPmuspnotice from '../Academics/Scholorships/ScholorShip data/NSP PM-USP Notice.jpg'
import nspPmuspGuideline from '../Academics/Scholorships/ScholorShip data/PM-USP Guidelines.pdf'
import RegardinginspctionofServicebokaspersr202 from './Notification Files/Regarding inspection of service book as per SR 202.jpg'
import advertforAccountbranchofDPSRU from '../Recruitments/Recruitment Data/Advertisment for Accounts Branch of DPSRU.pdf'



function Notifications() {
  const [status, setStatus] = useState(false);
  return (
    <div className="dpsru-every-component-css">
      <div className="inner-container">
        <h3 className="dpsru-primary-heading-for-every-page">NOTIFICATIONS</h3>
        <hr />

        <ol className=" ">
          {/* ****************************************** */}



          {/* <li>
            <a href={Feenoticeforevensemesterexam}>Fees Notice for Even Semester Examination</a> 
            <img src={newgif} alt="new" />
          </li> */}
          <li>
          <a href={advertforAccountbranchofDPSRU}>
          ENGAGEMENT OF A RETIRED GOVERNMENT OFFICER AS A CONSULTANT/ADVISOR/EXPERT ON CONTRACT BASIS
              </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
          <a href={RegardinginspctionofServicebokaspersr202}>
          Inspection of service book as per SR 202
              </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
          <a href={nspPmuspGuideline}>
          PM-USP Guidelines
              </a>{" "}
            <img src={newgif} alt="new" />
          </li>

          <li>
          <a href={nspPmuspnotice}>
          NSP PM-USP Notice
              </a>{" "}
            <img src={newgif} alt="new" />
          </li>


          <li>
          <a href={walkinintervidevisitionmedicafac}>
          Walk-in interview for visiting faculty in the School of Physiotherapy
              </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
          <a href={ofrderFCDpsru}>
          Order-FC-DPSRU
              </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeE2QdMBuHEdxr36hge4YCaNYZ7fTe7Dy85T4mEsiqdpyaUVg/viewform">
               Internet Users Account Creation Form
              </a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={newRevisedAdmissionSchedule}>New revised admission Schedule 2024-25</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={rollingadvofrguestfac}>ROLLING ADVERTISEMENT FOR GUEST/VISITING FACULTY POSITIONS</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={ExtensionofRegistrationdateforugcourses}>Extension of registration date for UG Courses</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={icmrprojectresearchscientisnosnsme}>Applications are invited for the post of ICMR-Project Research Scientist - I (Non-Medical)" (Last date: May 27, 2024)"</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={noticeforsubmissionofsessionalmarkofdhpahrm}>Notice for submission of sessional Marks of D.Pharm. and Pre-PhD Examination</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href='https://dpsruonline.in/'>Click here for Admissions to UG & PG Courses for the year 2024-25</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={examFeeNoticeAprmay24}>Examination Fees Notice Even Semester Students</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={admissioninformationforPGandUGcorses}>ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={examformnoticeforprephdstudents}>Examinations Form Notice for Pre. Ph.D. Students</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={engagementofaRetiredgovoff}>Engagement of a Retired Government Officer on Contract Basis</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={AwardpfPHDtomsRUchiTripathi}>Notification for the Award of Ph.D Degree to Ms. Ruchi Tripathi</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={centerForprofessionDevinHigherEduc}>Centre for Professional Development in Higher Education (CPDHE)</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={weedingoutpolicyofficeorder31jan24}>Weeding out the old record DPSRU-1</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={ReOpeningofoddsemfeeportalnotice}>Re-Opening of Odd Semester Fee Portal, NOTICE</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={weedingoutpolicyofficeorder}>Weeding out the old record - Office order</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={convoAwadiName}>List of Awardee’s for 6th Convocation 2023</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={noticeforrehearsaltimingforcovo21stdecandceremony}>Notice for Rehearsal timings for convocation on 21st December and  ceremony instructions for convocation on 22nd December 2023</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={recrutmentofjrffellow}>Advertisement for the Post of Junior Research Fellow (JRF)</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={callingsealedquotationsforflowerdecorrr}>Calling sealed quotations for flower decorations at 6th convocation in DPSRU</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={noticeforprofssagarwaltropyforbptstudents}>Notice for PROF. S.S. AGRAWAL TROPHY for BPT students</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={finalspotroundformbaadmission}>Final spot round for MBA admission</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={reopeningoffeeportalforoddsem}>Reopening of fee portal for odd semester</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={walkininterviewresulton17}>Result of Walk-in-Interview for Guest Faculties held on 17/11/2023</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={finalListOfEligibleCandidatesForstudentsCouncil}> Final list of Eligible Candidates for Students Council Elections 2023-24 DPSRU</a> 
            <img src={newgif} alt="new" />
          </li>

          <li>
            <a href={walkinInterviewResult}> Result of Walk-in-Interview for Guest Faculties held on 03/11/2023</a> 
            <img src={newgif} alt="new" />
          </li>

          <li>
            <a href={walkinInterviewOn17Nov}> Walk-in Interview for Guest Faculties of Pharmacy and Physiotherapy on 17/11/2023 (Reporting Time strictly from 10:00 AM to 10:30 AM)</a> 
            <img src={newgif} alt="new" />
          </li>

          <li>
            <a href={convoForm6th}>6th convocation registration form 2023</a> 
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={CircularForTakinganOrganistionalOath}>Observance of Vigilance Awareness Week 2023 in DPSRU from 30 Oct to 05 Nov 2023 (Circular for Organizational Oath)</a> 
            <img src={newgif} alt="new" />
          </li>


          <li>
            <a href={circularForTakingIntegrityPledge}>Circular for taking integrity pledge Individually</a> || <a href="https://pledge.cvc.nic.in/" style={{color:"#c10000"}}>Click here..</a>
            <img src={newgif} alt="new" />
          </li>









          <li>
            <a href={UFMNotice}>UFM Notice</a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={oddSemFeeNotice}>Odd semester fee notice</a>{" "}
            <img src={newgif} alt="new" />
          </li>

        
          <li>
            <a href={NotificationPhdDegreeMsNeha}>   Notification for the Award of Ph.D Degree to Ms. Neha</a>{" "}
            <img src={newgif} alt="new" />
          </li>
          <li>
            <a href={NotificationPhdDegreeMsSweta}>    Notification for the Award of Ph.D Degree to Ms. Shweta Paroha</a>{" "}
            <img src={newgif} alt="new" />
          </li>









          <li>
            <a href={walkininterviewguest20oct}>"Walk-in- Interview for Guest Faculties on 03/11/2023 (Reporting Time strictly from 10:00 AM to 11:00 AM)</a>{" "}
            <img src={newgif} alt="new" />
          </li>

          
        

       




          <li>
            <a href='https://dpsruonline.in/'>Admission open UG Courses Final Spot Round - <b style={{color:'red'}}>Apply Here</b></a>{" "}
            <img src={newgif} alt="new" />
          </li>


          <li>
            <a href={convoNotice}>6th Convocation Notice</a>{" "}
            <img src={newgif} alt="new" />
          </li>



          <li>
            <a href={phdCourseWork}>Pre PhD course work</a>{" "}
            <img src={newgif} alt="new" />
          </li>

          <li>
            <a href={newdata001}>
              Ph.D Viva-Voce Notification of Ms. Shweta Paroha
            </a>{" "}
            <img src={newgif} alt="new" />
          </li>

          <li>
            <a href={newdata002}>
              Notification for the Award of Ph.D Degree to Ms. Meenu Grover
              Sharma
            </a>{" "}
            
          </li>
          <li>
            <a href={newdata003}>
              Date extended Notice for Final Admission Pre-PhD 2023-24
            </a>{" "}
            
          </li>
          <li>
            <a href={newdata004}>
              List of selected candidates for the Final Admissions to Pre-PhD
              2023-24
            </a>{" "}
            
          </li>
          <li>
            <a href={newdata005}>Notice for final admission Pre-PhD 2023-24</a>{" "}
            
          </li>
          <li>
            <a href={newdata006}>Result of PhD Entrance Examination 2023-24</a>{" "}
            
          </li>
          <li>
            <a href={newdata007}>
              Notice and List of candidates of Pre-PhD Interview, September 2023
            </a>{" "}
            
          </li>
          <li>
            <a href={newdata008}>
              List of Approved Supervisors, September 2023
            </a>{" "}
            
          </li>
          <li>
            <a href={newdata009}>
              <span>Result of MPT entrance exam. 2023</span>
            </a>{" "}
            
          </li>
          <li>
            <a href={newdata010}>
              Notice and Revised list of Candidates for Pre-PhD Entrance test
            </a>{" "}
            
          </li>
          <li>
            <a href="https://dpsruonline.in/">
              Special Spot Round for UG courses : Apply Here
            </a>{" "}
            
          </li>
          <li>
            <a href={vacantSeatforSpecial}>
              Vacant Seats for Special Spot Round Starting from 25/8/2023
            </a>{" "}
            
          </li>
          <li>
            <a href={specialSpotRoundC}>
              Special spot round for UG courses (Fresh candidates can also
              apply) will start from 25/08/23
            </a>{" "}
            
          </li>
          <li>
            <a href={ReOpeningOfSemester}>
              Reopening of semester fee with late fine
            </a>{" "}
            
          </li>
          <li>
            <a href={PhdVivaVocaNotiMsMeenuG}>
              PhD Viva-Voce Notification of Ms. Meenu Grover Sharma
            </a>{" "}
            
          </li>
          <li>
            <a href={Circular4823}>Circular </a> 
          </li>
          <li>
            <a href={DocVerificationNotice}>
              1st Notice regarding document verification at University for UG
              Courses
            </a>{" "}
            
          </li>
          <li>
            <a
              href={
                NoticeforextensionoftheonlineapplicationdeadlinefortheBPharmLEandPGcourses
              }
            >
              Notice for extension of the online application deadline for the B.
              Pharm LE and PG courses
            </a>
          </li>
          <li>
            <a href={Circular02}> Circular</a>
          </li>
          <li>
            <a href={YogaAndFitness}>
              Yoga and Fitness center Admission Notice
            </a>
          </li>
          <li>
            <a href={Firstroundonlinecounsellingschedule202}>
              First round online counselling schedule-2023
            </a>
          </li>
          <li>
            <a href="https://dpsruonline.in/">
              To apply for B. Pharm. (LE) and PG courses: Click here (Last date
              10/08/23)
            </a>
          </li>
          <li>
            <a
              href={
                RevisedProvisionalMeritListsofDelhicandidatesforUGcourses2023
              }
            >
              Provisional Merit Lists of Delhi candidates for UG courses-2023
            </a>
          </li>
          <li>
            <a
              href={
                RevisedProvisionalMeritListsofNONDelhicandidatesforUGcourses2023
              }
            >
              Provisional Merit Lists of Non-Delhi candidates for UG
              courses-2023
            </a>
          </li>
          <li>
            <a
              href={
                AlphabeticalListofcandidateswhohavenotfilledcoursePreferences
              }
            >
              Alphabetical List of candidates who have not filled course
              Preference
            </a>
          </li>
          <li>
            <a href={Commoninstructionsforskilltestfornonteachingposts}>
              Common instructions for skill test for non-teaching posts
            </a>
          </li>
          <li>
            <a href={NoticeregardingUGcoursespreferencefilling202324}>
              Notice regarding UG courses preference filling 2023-24
            </a>
          </li>
          <li>
            <a href={NOTICEofSkillTest}>
              Schedule of Skill Test for recruitment of Non-Teaching Posts
              (Group ‘B’ ‘C’ Ministerial Technical)
            </a>
          </li>
          <li>
            <a href={Document570}>Circular</a>
          </li>
          <li>
            <a href={EmployementNotice}>Employment Notice</a>
          </li>
          <li>
            <a href={Prospectus2023forweb}>Prospectus 2023 </a>
          </li>
          <li>
            <a href={AdvertisementofJRF}>
              “Advertisement for the Post of CCRUM – JRF”
            </a>
          </li>
          <li>
            <a href={Advertisement2023241}>
              “Announcement of Admissions (2023-24)”
            </a>
          </li>
          <li>
            <a href={PhdAdmission202324}>Notice for PhD Admission </a>
          </li>
          <li>
            <a href={CorrigendumofNTS1}>
              Extension of dates of uploading of Documents for Group B C Post
              and Corrigendum of Junior Stenographer
            </a>
          </li>
          {status ? (
            <div>
              <li>
                <a href={noticeOld38}>
                  Corrigendum to Recruitment Notice No. DPSRU/NTS/2022/01
                </a>
              </li>
              <li>
                <a href="http://non-teaching02nov.altioretech.in/DPSRU/frm_Register.aspx">
                  Application link for Group ‘A’ Non-Teaching Positions vide
                  Recruitment Notice No. DPSRU/NTS/2022/02
                </a>
              </li>
              <li>
                <a href={noticeOld40}>
                  Dilli ki Yogshala Notice 2
                </a>
              </li>
              <li>
                <a href={noticeOld41}>
                  Dilli ki Yogshala Notice 1
                </a>
              </li>
              <li>
                <a href={noticeOld42}>
                  Recruitment Notice No. DPSRU/NTS/2022/02
                </a>
              </li>
              <li>
                <a href="http://www.dpsru.edu.in">
                  Link for Application for Group ‘A’ Non-Teaching Positions will
                  be available soon
                </a>
              </li>
              <li>
                <a href={noticeOld44}>
                  DPSRU 5th Annual Convocation Announcement
                </a>
              </li>
              <li>
                <a href={noticeOld45}>
                  Registration Form for the 5th Annual Convocation
                </a>
              </li>
              <li>
                <a href="/">
                  Spot Round for UG Courses will be announced soon. Candidates
                  are advised to visit the website regularly for further update
                </a>
              </li>
              <li>
                <a href="https://cdn.digialm.com//EForms/configuredHtml/1258/79113//Instruction.html">
                  Application Link for Regular Non-Teaching Posts advertised
                  vide Recruitment Notice No. DPSRU/NTS/2022/01
                </a>
              </li>
              <li>
                <a href="http://pledge.cvc.nic.in">
                  All the students, teaching and non teaching staff are
                  requested to take an integrity pledge on this link
                </a>
              </li>
              <li>
                <a href={noticeOld49}>
                  Vigilance Awareness Week Workshop Notice
                </a>
              </li>
              <li>
                <a href={noticeOld50}>
                  Notice regarding 5th Annual Convocation of DPSR University
                </a>
              </li>
              <li>
                <a href="https://forms.gle/j3sKgfBsgpnjvNbBA">
                  Link to fill the sports survey form by students, faculty, and
                  staff
                </a>
              </li>
              <li>
                <a href={noticeOld52}>
                  Notice for Students, Faculty and Staff
                </a>
              </li>
              <li>
                <a href={noticeOld53}>
                  Last Date of Applications for the admission of Executive
                  MPharm (DRA), MSc AI in Medicine, PGDCR and DPSRU NIB Joint
                  Certificate Course in Quality Assurance of Biopharmaceuticals
                  extended to Monday 29th August 2022
                </a>
              </li>
              <li>
                <a href={noticeOld54}>
                  Post Matric and Merit cum Means Scholarship
                </a>
              </li>
              <li>
                <a href={noticeOld55}>
                  Applications Invited: Certificate Course in Quality Assurance
                  of Bio-Pharmaceuticals || Last Date : Today (August 22, 2022)
                </a>
              </li>
              <li>
                <a href={noticeOld56}>
                  Notice: Closure of admissions to DMYS
                </a>
              </li>
              <li>
                <a href={noticeOld57}>
                  Admission Notice: Choice Filling Option in UG Courses Reopened
                  till 23.08.2022
                </a>
              </li>
              <li>
                <a href={noticeOld58}>
                  Admission Notice: One year programme in Diploma in Meditation
                  Yoga Sciences : CMYS
                </a>
              </li>
              <li>
                <a href={noticeOld59}>
                  Notice for Ph.D Viva-voce of Mr. Kushagra Khanna
                </a>
              </li>
              <li>
                <a href={noticeOld60}>
                  Pre-Ph.D. Time Table
                </a>
              </li>
              <li>
                <a href={noticeOld61}>
                  Pre-Ph.D. Circular
                </a>
              </li>
              <li>
                <a href={noticeOld62}>
                  Notice: On Spot Admissions || Certificate Course in Yoga
                </a>
              </li>
              <li>
                <a href={noticeOld63}>
                  DPSRU Celebrates Independence Day 2022
                </a>
              </li>
              <li>
                <a href="https://forms.gle/BS7BfRBp4rPMuhzp6">
                  Interest form for Har Ghar Tiranga Contests – Independence Day
                  Celebration
                </a>
              </li>
              <li>
                <a href={noticeOld65}>
                  Girls Hostel Notice: 2nd Re-allotment list of Girls Hostel for
                  Session 2022
                </a>
              </li>
              <li>
                <a href={noticeOld66}>
                  Boys Hostel Notice: 2nd Re-allotment list of Boys Hostel for
                  Session 2022
                </a>
              </li>
              <li>
                <a href={noticeOld67}>
                  Girls Hostel Notice : Submission of fees for cooler usage
                </a>
              </li>
              <li>
                <a href={noticeOld68}>
                  Girls Hostel Notice: Submission of Hostel Mess Fees
                </a>
              </li>
              <li>
                <a href={noticeOld69}>
                  Girls Hostel Notice: Re-allotment list of Boys Hostel for
                  Session 2022
                </a>
              </li>
              <li>
                <a href={noticeOld70}>
                  Boys Hostel Notice : Submission of fees for cooler usage
                </a>
              </li>
              <li>
                <a href={noticeOld71}>
                  Boys Hostel Notice: Submission of Hostel Mess Fees
                </a>
              </li>
              <li>
                <a href={noticeOld72}>
                  Boys Hostel Notice: Re-allotment list of Boys Hostel for
                  Session 2022
                </a>
              </li>
              <li>
                <a href={noticeOld73}>
                  Hostel Notice
                </a>
              </li>
              <li>
                <a href={noticeOld74}>
                  List of Students Selected for Pre-Ph.D. 2022
                </a>
              </li>
              <li>
                <a href={noticeOld75}>
                  Pre-Ph.D. 2022 Notice and Annexures
                </a>
              </li>
              <li>
                <a href={noticeOld76}>
                  List of candidates selected for the post of Assistant
                  Professor BMLT, DMLT, B.Pharm Ayurveda, Diploma in Pharmacy,
                  Pharmaceutics, BBA Community healthcare and MBA Pharma
                  Economics Trade
                </a>
              </li>
              <li>
                <a href={noticeOld77}>
                  The list of Eligible candidates for interview for the post of
                  Assistant Professor (MBA Pharma Economics and Trade)
                </a>
              </li>
              <li>
                <a href={noticeOld78}>
                  List of Candidates for pre-Ph.D. Interview June-2022
                </a>
              </li>
              <li>
                <a href={noticeOld79}>
                  Pre-Ph.D. Interview Notice 2022
                </a>
              </li>
              <li>
                <a href={noticeOld80}>
                  Result of Pre Ph.D. Entrance Examination, June-2022
                </a>
              </li>
              <li>
                <a href={noticeOld81}>
                  The list of Eligible candidates for interview for the post of
                  Assistant Professor BBA (Community Healthcare)
                </a>
              </li>
              <li>
                <a href={noticeOld82}>
                  Notification for Ph.D Viva-vice of Mr. Mrityunjay Singh
                </a>
              </li>
              <li>
                <a href={noticeOld83}>
                  The list of candidates shortlisted for interview for the post
                  of Assistant Pr ofessor (Diploma in Pharmacy) and Assistant
                  Professor Pharmaceutics
                </a>
              </li>
              <li>
                <a href={noticeOld84}>
                  Notice for pre-PhD entrance test
                </a>
              </li>
              <li>
                <a href={noticeOld85}>
                  List of Candidates Eligible for pre-PhD entrance test – 2022
                </a>
              </li>
              <li>
                <a href={noticeOld86}>
                  Result for the Post of Assistant Registrar on Contractual
                  Basis
                </a>
              </li>
              <li>
                <a href={noticeOld87}>
                  Walk-In Interviews for Visiting Faculty
                </a>
              </li>
              <li>
                <a href={noticeOld88}>
                  List of Eligible candidates for the post of Assistant
                  Registrar on contractual basis
                </a>
              </li>
              <li>
                <a href={noticeOld89}>
                  List of Eligible candidates for the post of Assistant
                  Professor in BMLT, DMLT and B.Pharma (Ayurveda)
                </a>
              </li>
              <li>
                <a href={noticeOld90}>
                  Applications Invited for Ph.D. Admissions
                </a>
                ||
                <a href={noticeOld90b}>
                  Ph.D. Admission Form
                </a>
              </li>
              <li>
                <a href="https://forms.gle/Stwz3vmTiP8EfDCL6">
                  Application Link for the Post of Assistant Registrar on
                  Contractual Basis
                </a>
              </li>
              <li>
                <a href={noticeOld92}>
                  General Instructions and Eligibility Criteria for Assistant
                  Registrar on Contract Basis
                </a>
              </li>
              <li>
                <a href={noticeOld93}>
                  Third List – Boys Hostel
                </a>
              </li>
              <li>
                <a href={noticeOld94}>
                  Corrigendum to Recruitment Notification No.
                  DPSRU/TEACHING/2022/01 and No. DPSRU/TEACHING/2022/02
                </a>
              </li>
              <li>
                <a href={noticeOld95}>
                  Recruitment Notice for Assistant Professor Diploma Pharmacy
                </a>
              </li>
              <li>
                <a href={noticeOld96}>
                  Annexure 1 – Recruitment Rules (Diploma in Pharmacy)
                </a>
              </li>
              <li>
                <a href={noticeOld97}>
                  Annexure 2- Self-Declaration Format for-OBC-Certificate
                </a>
              </li>
              <li>
                <a href={noticeOld98}>
                  Faculty Recruitment Notice No. DPSRU/TEACHING/2022/01
                </a>
              </li>
              <li>
                <a href={noticeOld99}>
                  Annexure – 1: Recruitment Rules
                </a>
              </li>
              <li>
                <a href={noticeOld100}>
                  Annexure – 2: Self Declaration Format for OBC Certificate
                </a>
              </li>
              <li>
                <a href={noticeOld101}>
                  List of candidates selected for the post of Assistant
                  Professor Physiotherapy, Pharmaceutics and Pharmacognosy
                </a>
              </li>
              <li>
                <a href={noticeOld102}>
                  List of Candidates Shortlisted for Interview for the post of
                  Assistant Professor Pharmacognosy – Regular
                </a>
              </li>
              <li>
                <a href={noticeOld103}>
                  Cancellation of Recruitment of Various Non-Teaching Post
                  Advertised in the Year 2019 and 2020
                </a>
              </li>
              <li>
                <a href={noticeOld104}>
                  List of Candidates Shortlisted for Interview for the post of
                  Assistant Professor Pharmaceutics-Regular
                </a>
              </li>
              {/* <li>
                <a href="https://dpsru.edu.in/archived-notifications/">
                  
                  OLDER NOTIFICATIONS
                </a>
              </li> */}
            </div>
          ) : null}
          {status ? (
            <button
              onClick={() => setStatus(!status)}
              className="read-more-btn"
            >
              Read less..
            </button>
          ) : (
            <button
              onClick={() => setStatus(!status)}
              className="read-more-btn"
            >
              Read More..
            </button>
          )}
        </ol>
      </div>
    </div>
  );
}

export default Notifications;
