import React from "react";
import "./Results.css";
import { Link } from "react-router-dom";
import imgGif from "../../NEW/new.gif";

import oldres01 from "./ResultsData/Revised-Result-of-MBA-HHM-1st-Semester-Examination-March-2023.pdf";
import oldres02 from "./ResultsData/REVISED-RESULT-OF-M.PHARM-1ST-SEMESTER-SUPPLE.-EXAM.-INDUSTRIAL-PHARMACY-SPS-APRIL-2023.pdf";
import oldres03 from "./ResultsData/Result-of-BPT-6th-Semester-Examination-July-2023.pdf";
import oldres04 from "./ResultsData/Result-of-D.Pharm_.-1st-Year-Annual-Examination-July-2023.pdf";
import oldres05 from "./ResultsData/Result-of-D.Pharm_.-1st-Year-Supplementary-Examination-July-2023.pdf";
import oldres06 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-3rd-Semester-Supplementary-Examination-Aug-2023.pdf";
import oldres07 from "./ResultsData/Result-of-BPT-8th-Semester-Examination-July-2023.pdf";
import oldres08 from "./ResultsData/Result-of-B.-Sc.-Hons.-6th-Semester-Examination-July-2023-Pranav-Dalal.pdf";
import oldres09 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-6th-Semester-Examination-July-2023.pdf";
import oldres10 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-6th-Semester-Supplementary-Examination-July-2023.pdf";
import oldres11 from "./ResultsData/Result-of-D.Pharm_.-2nd-Year-Examination-June-2023-1.pdf";
import oldres12 from "./ResultsData/Result-of-D.Pharm_.-2nd-Year-Supplementary-June-2023.pdf";
import oldres13 from "./ResultsData/RESULT-OF-B.PHARM-8TH-SEMESTER-EXAMINATION-SPS-JUNE-2023.pdf";
import oldres14 from "./ResultsData/RESULT-OF-B.PHARM-8TH-SEMESTER-EXAMINATION-DIPSAR-JUNE-2023.pdf";
import oldres15 from "./ResultsData/Result-of-B.Pharm-Ayurveda-1st-Semester-Supplementary-Examination-April-2023.pdf";
import oldres16 from "./ResultsData/Result-of-M.Sc-AIPM-3rd-Semester-Examination-April-2023.pdf";
import oldres17 from "./ResultsData/RESULT-OF-M.PHARM-1ST-SEMESTER-EXAMINATION-DIPSAR-APRIL-2023.pdf";
import oldres18 from "./ResultsData/RESULT-OF-M.PHARM-1ST-SEMESTER-EXAMINATION-SPS-APRIL-2023.pdf";
import oldres19 from "./ResultsData/RESULT-OF-M.PHARM-1ST-SEMESTER-SUPPLE.-EXAM.-SPS-JULY-2023.pdf";
import oldres20 from "./ResultsData/RESULT-OF-M-PHARM-3RD-SEMESTER-EXAM.-SPS-JULY-2023-1.pdf";
import oldres21 from "./ResultsData/Result-of-M.Pharm-3rd-semester-Exam-DIPSAR-April-2023.pdf";
import oldres22 from "./ResultsData/RESULT-OF-B.PHARM-2ND-SEMESTER-SUPPLE.-EXAMINATION-DIPSAR-APRIL-2023-1.pdf";
import oldres23 from "./ResultsData/RESULT-OF-B.PHARM-2ND-SEMESTER-SUPPLE.-EXAMINATION-SPS-APRIL-2023-1.pdf";
import oldres24 from "./ResultsData/Result-of-M.-Sc.-Sports-Science-1st-Semester-Examination-April-2023.pdf";
import oldres25 from "./ResultsData/Result-of-M.-Sc.-Sports-Science-3rd-Semester-Examination-April-2023.pdf";
import oldres26 from "./ResultsData/RESULT-OF-B.PHARM-2ND-SEMESTER-SUPPLE.-EXAMINATION-SPS-APRIL-2023.pdf";
import oldres27 from "./ResultsData/RESULT-OF-B.PHARM-3RD-SEMESTER-SUPPLE.-EXAMINATION-DIPSAR-APRIL-2023.pdf";
import oldres28 from "./ResultsData/Result-of-B.Pharm-Ayurveda-1st-Semester-Examination-April-2023.pdf";
import oldres29 from "./ResultsData/Result-of-M.Sc_.-AIM-1st-Semester-Examination-April-2023-1.pdf";
import oldres30 from "./ResultsData/Result-of-MBA-ITM-1st-Semester-Examination-March-2023.pdf";
import oldres31 from "./ResultsData/Result-of-BPT-2nd-Semester-Supplementary-Examination-April-2023.pdf";
import oldres32 from "./ResultsData/Result-of-MBA-PM-1st-Semester-Examination-March-2023.pdf";
import oldres33 from "./ResultsData/Result-of-B.-Pharm-7th-Semester-Examination-Old-Batch-DIPSAR-June-2023.pdf";
import oldres34 from "./ResultsData/Result-of-B.-Pharm-7th-Semester-Examination-DIPSAR-June-2023.pdf";
import oldres35 from "./ResultsData/Result-of-BPT-8th-Semester-Supplementary-Examination-April-2023.pdf";
import oldres36 from "./ResultsData/Result-of-BPT-6th-Semester-Supplementary-Examination-April-2023.pdf";
import oldres37 from "./ResultsData/Result-of-BPT-4th-Semester-Supplementary-Examination-April-2023.pdf";
import oldres38 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-1st-Semester-Supplementary-Examination-April-2023.pdf";
import oldres39 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-1st-Semester-Examination-April-2023.pdf";
import oldres40 from "./ResultsData/Result-of-B.Pharm-8th-Semester-Examination-Old-Batch-SPS-June-2023.pdf";
import oldres41 from "./ResultsData/Result-of-B.Pharm-6th-Semester-Supple.-Examination-Old-Batch-SPS-April-2023.pdf";
import oldres42 from "./ResultsData/Result-of-B.Pharm-6th-Semester-Supple.-Examination-DIPSAR-April-2023.pdf";
import oldres43 from "./ResultsData/Result-of-B.Pharm-4th-Semester-Supple.-Examination-L.E-SPS-April-2023.pdf";
import oldres44 from "./ResultsData/Result-of-B.Pharm-4th-Semester-Supple.-Examination-SPS-April-2023.pdf";
import oldres45 from "./ResultsData/Result-of-B.Pharm-4th-Semester-Supple.-Examination-DIPSAR-April-2023.pdf";
import oldres46 from "./ResultsData/Result-of-B.Pharm-6th-Semester-Supple.-Examination-SPS-April-2023.pdf";
import oldres47 from "./ResultsData/Result-of-B.Pharm-4th-Semester-Supple.-Examination-Old-Batch-DIPSAR-April-2023.pdf";
import oldres48 from "./ResultsData/Result-of-B.Pharm-Ayurveda-3rd-Semester-Examination-April-2023.pdf";
import oldres49 from "./ResultsData/Result-of-MPH-1st-Semester-Examination-March-2023.pdf";
import oldres50 from "./ResultsData/Result-of-MBA-PM-1st-Semester-Supplementary-Examination-April-2023.pdf";
import oldres51 from "./ResultsData/Result-of-MBA-HHM-1st-Semester-Examination-March-2023.pdf";
import oldres52 from "./ResultsData/Result-of-MBA-PET-1st-Semester-Supplementary-Examination-March-2023.pdf";
import oldres53 from "./ResultsData/Result-of-B.Pharm-3rd-Semester-Supplementary-Examination-SPS-April-2023.pdf";
import oldres54 from "./ResultsData/Result-of-B.Pharm-3rd-Semester-Lateral-Entry-Examination-SPS-April-2023.pdf";
import oldres55 from "./ResultsData/Result-of-B.Pharm-3rd-Semester-Lateral-Entry-Examination-DIPSAR-April-2023.pdf";
import oldres56 from "./ResultsData/Result-of-BPT-3rd-Semester-Examination-March-2023.pdf";
import oldres57 from "./ResultsData/Result-of-BPT-1st-Semester-Examination-March-2023.pdf";
import oldres58 from "./ResultsData/Result-of-BPT-1st-Semester-Supplementary-Examination-March-2023.pdf";
import oldres59 from "./ResultsData/Result-of-DMLT-1st-Semester-Supplementary-Examination-April-2023.pdf";
import oldres60 from "./ResultsData/Result-of-BBA-CH-1st-Semester-Supplementary-Examination-March-2023.pdf";
import oldres61 from "./ResultsData/Result-of-BMLT-1st-Semester-Supplementary-Examination-April-2023.pdf";
import oldres62 from "./ResultsData/Result-of-BMLT-1st-Semester-Examination-April-2023.pdf";
import oldres63 from "./ResultsData/Result-of-BBA-HC-1st-Semester-Examination-March-2023.pdf";
import oldres64 from "./ResultsData/Result-of-MPT-Sports-Medicine-Cardiovascular-and-Pulmonary-Pediatrics-1st-Semester-Examination-March-2023.pdf";
import oldres65 from "./ResultsData/Result-of-DMLT-1st-Semester-examination-April-2023-1.pdf";
import oldres66 from "./ResultsData/Result-of-B.Pharm-8th-Semester-Supple.-Examination-SPS-April-2023.pdf";
import oldres67 from "./ResultsData/Result-of-MPT-SM-3rd-Semester-Examination-March-2023.pdf";
import oldres68 from "./ResultsData/Revised-Result-of-DMYS-3rd-Trimester-October-2022.pdf";
import oldres69 from "./ResultsData/Result-of-B.Pharm-5th-Semester-Examination-DIPSAR-March-2023.pdf";
import oldres70 from "./ResultsData/Result-of-B.Pharm-5th-Semester-Examination-SPS-March-2023.pdf";
import oldres71 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-6th-Semester-Examination-July-2022-Result-Later-Students.pdf";
import oldres72 from "./ResultsData/Result-of-MBA-PET-3rd-Semester-Examination-March-2023.pdf";
import oldres73 from "./ResultsData/Result-of-B.Sc_.-Hons-Biomedical-Science-1st-Semester-Examination-March-2023.pdf";
import oldres74 from "./ResultsData/Resul-of-MPH-3rd-Semester-Examination-March-2023.pdf";
import oldres75 from "./ResultsData/Result-of-MHA-3rd-Semester-Examination-March-2023.pdf";
import oldres76 from "./ResultsData/Result-of-MBA-PM-3rd-Semester-Examination-March-2023.pdf";
import oldres77 from "./ResultsData/Result-of-BBA-CH-3rd-Semester-examination-March-2023_1-1.pdf";
import oldres78 from "./ResultsData/Result-of-BMLT-3rd-Semester-examination-March-2023.pdf";
import oldres79 from "./ResultsData/Result-of-DMLT-3rd-Semester-examination-March-2023-1.pdf";
import oldres80 from "./ResultsData/Result-of-B.Pharm-1st-Semester-Examination-SPS-March-2023.pdf";
import oldres81 from "./ResultsData/Result-of-B.Pharm-1st-Semester-Examination-DIPSAR-March-2023.pdf";
import oldres82 from "./ResultsData/Result-of-B.-Sc.-Hons.-3rd-Semester-Supplementary-Examination-Feb-2023.pdf";
import oldres83 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-5th-Semester-Supplementary-Examination-Feb-2023.pdf";
import oldres84 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-3rd-Semester-Supplementary-Examination-Feb-2023-Old-Batch-Student.pdf";
import oldres85 from "./ResultsData/Result-of-Pre.-Ph.D-Examination-Feb-2023.pdf";
import oldres86 from "./ResultsData/Result-of-SAYA-WCSC-2nd-Semester-Examiation-January-2023.pdf";
import oldres87 from "./ResultsData/Result-of-MMC-WCSC-2nd-Semester-Examination-January-2023-1.pdf";
import oldres88 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Sciences-3rd-Semester-Examination-Feb-2023.pdf";
import oldres89 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Sciences-5th-Semester-Examination-Feb-2023.pdf";
import oldres90 from "./ResultsData/Result-D.Pharm-1st-year-Supplementary-Feb-2023-1.pdf";
import oldres91 from "./ResultsData/Result-of-BPT-5th-Semester-Examination-Jan-2023.pdf";
import oldres92 from "./ResultsData/Result-of-B.Pharm-7th-Semester-Examination-DPSRU-February-2023.pdf";
import oldres93 from "./ResultsData/Result-of-B.Pharm-7th-Semester-Examination-DIPSAR-February-2023.pdf";
import oldres94 from "./ResultsData/Result-of-BPT-7th-Semester-Examination-Jan-2023.pdf";
import oldres95 from "./ResultsData/Result-of-B.Pharm-5th-Semester-Supple.-Examination-Old-Batch-DIPSAR-January-2023.pdf";
import oldres96 from "./ResultsData/Result-of-B.Pharm-5th-Sem.-Supple.-Examination-DPSRU-January-2023.pdf";
import oldres97 from "./ResultsData/Result-of-B.Pharm-5th-Sem.-Supple.-Examination-DIPSAR-January-2023.pdf";
import oldres98 from "./ResultsData/Result-of-B.Pharm-3rd-Sem.-Supple.-Examination-Old-Batch-DIPSAR-January-2023.pdf";
import oldres99 from "./ResultsData/Result-of-B.Pharm-3rd-Sem.-Supple.-Examination-DIPSAR-January-2023.pdf";
import oldres100 from "./ResultsData/Result-of-B.Pharm-1st-Sem.-Supple.-Examination-DPSRU-January-2023.pdf";
import oldres101 from "./ResultsData/Result-of-B.Pharm-1st-Sem.-Supple.-Examination-DIPSAR-January-2023.pdf";
import oldres102 from "./ResultsData/Result-of-BPT-3rd-Semester-Supplementary-Examination-Jan-2023.pdf";
import oldres103 from "./ResultsData/Result-of-BPT-1st-Semester-Supplementary-Examination-Jan-2023.pdf";
import oldres104 from "./ResultsData/Result-of-DMYS-Supple.-Trimester-3.pdf";
import oldres105 from "./ResultsData/Result-of-B.Pharm-2nd-Semester-Supplementary-Examination-DPSRU-2022..pdf";
import oldres106 from "./ResultsData/BPT-2nd-Semester-Supplementary-Examination-Sep-2022.pdf";
import oldres107 from "./ResultsData/Result-of-B.-Pharm-2nd-Semester-Supplementary-Examination-October-2022..pdf";
import oldres108 from "./ResultsData/Result-of-BPT-4th-Semester-Supplementary-Examination-Aug-2022.pdf";
import oldres109 from "./ResultsData/Result-of-B.-Sc.-Hons-Sports-Science-4th-Semester-Supplementary-Examination-Aug-2022.pdf";
import oldres110 from "./ResultsData/Result-of-B.-Pharm-6th-Semester-Supplementary-Examination-August-2022.pdf";
import oldres111 from "./ResultsData/Result-of-B.Pharm-8th-Semester-Exam-DPSRU-Old-Batch-August-2022.pdf";
import oldres112 from "./ResultsData/B.Pharm-7th-Semester-Supple.-Exam-Old-Batch-DPSRU-August-2022.pdf";
import oldres113 from "./ResultsData/Result-of-D.-Pharm-2nd-Year-Supplementary-Examination-August-2022.pdf";
import oldres114 from "./ResultsData/Result-of-B.-Pharm-7th-Semester-Supplementary-Examination-DIPSAR-August-2022..pdf";
import oldres115 from "./ResultsData/Result-of-B.-Pharm-7th-Semester-Supplementary-Examination-DPSRU-August-2022..pdf";
import oldres116 from "./ResultsData/Result-of-DMYS-Trimester-2-Batch-1-Supplementary-Examination-June-2022.pdf";
import oldres117 from "./ResultsData/Result-of-DMYS-Trimester-1-Batch1-2-Supplementary-Examinaton-June-2022.pdf";
import oldres118 from "./ResultsData/B.Pharm-1st-Sem-Supple.-Result.pdf";
import oldres119 from "./ResultsData/B.Pharm-7th-Sem-Supple.-Result.pdf";
import oldres120 from "./ResultsData/MHP-2nd-Sem-Supple.-Result.pdf";
import oldres121 from "./ResultsData/Result-of-B.Sc_.-Hons.-Sports-Sciences-1st-Semester-Examination-May-2022.pdf";
import oldres122 from "./ResultsData/Result-of-B.Pharm-3rd-Semester-Supplementary-Exam-DIPSAR-March-2022..pdf";
import oldres123 from "./ResultsData/Result-of-B.-Sc.-H.-Sports-Sciences-3rd-Semester-Supple.-Examination-March-2022.pdf";
import oldres124 from "./ResultsData/Result-of-Jyotsna-and-Vivek-Kumar-Gupta-B.Pharm-1st-Semester-Supple.-Exam-January-2022-2.pdf";
import oldres125 from "./ResultsData/Result-of-BPT-7th-Semester-Supple.-Examination-Jan-2022.pdf";
import oldres126 from "./ResultsData/Result-of-BPT-5th-Semester-Supplementary-Exam-Jan-2022.pdf";
import oldres127 from "./ResultsData/Result-of-MPCR-1st-Sem-Supple.-Exam-Jan.-2022.pdf";
import oldres128 from "./ResultsData/Result-of-MQA-1st-Sem-Supple.-Exam-Jan.-2022.pdf";
import oldres129 from "./ResultsData/Result-of-MIP-1st-Sem-Supple.-Exam-Jan.-2022.pdf";
import oldres130 from "./ResultsData/Result-of-MPB-1st-Sem-Supple.-Exam-Jan.-2022.pdf";
import oldres131 from "./ResultsData/Result-of-B.Pharm-5th-Sem-Supple.-DPSRU-Jan.-2022.pdf";
import oldres132 from "./ResultsData/Result-of-B.Pharm-5th-Sem-Supple.-Jan.-2022.pdf";
import oldres133 from "./ResultsData/Result-of-B.Pharm-3rd-Sem-Supple.-Exam-DPSRU-Jan.-2022.pdf";
import oldres134 from "./ResultsData/Result-of-B.Pharm-3rd-Sem-Supple.-Exam-L.E-DIPSAR-Jan.-2022.pdf";
import oldres135 from "./ResultsData/Result-of-Fatima-Subhi-B.Pharm-3rd-Sem-Supple.-Exam-Jan.-2022.pdf";
import oldres136 from "./ResultsData/Result-of-Ravi-kumar-Yadav-B.Pharm-3rd-Sem-Supple.-Exam-DPSRU-Jan.-2022.pdf";
import oldres137 from "./ResultsData/Result-of-Hemant-Kumar-B.Pharm-3rd-Sem-Supple.-Exam-DIPSAR-Jan.-2022.pdf";
import oldres138 from "./ResultsData/Result-of-B.Pharm-1st-Sem-Supple.-Exam-DPSRU-Jan.-2022.pdf";
import oldres139 from "./ResultsData/Result-of-B.Pharm-1st-Sem-Supple.-Exam-DIPSAR-Jan.-2022.pdf";
import oldres140 from "./ResultsData/Result-of-BPT-3rd-Semester-Supplementary-Examination-Jan-2022.pdf";
import oldres141 from "./ResultsData/Result-of-BPT-1st-Semester-Supplementary-Examination-Jan-2022-1.pdf";
import oldres142 from "./ResultsData/Result-of-B.Sc_.-6th-Semester-Supplementary-Exam-Dec-2021.pdf";
import oldres143 from "./ResultsData/Result-of-B.Sc_.-4th-semester-supple-Exam-Aug-2021.pdf";
import oldres144 from "./ResultsData/Result-of-B.Sc_.-2nd-semester-supple-Exam-Sep-2021.pdf";
import oldres145 from "./ResultsData/B.-Pharm-2nd-Semester-Supplementary-Result-Sep-2021.pdf";
import oldres146 from "./ResultsData/Result-of-BPT-2nd-Semester-Supplementary-Examination-Sep-2021.pdf";
import oldres147 from "./ResultsData/Result-of-D.Pharm-1st-Year-Supple.-2019-Batch.pdf";
import oldres148 from "./ResultsData/Result-of-D.Pharm-1st-Year-Supple.-2018-Batch.pdf";
import oldres149 from "./ResultsData/Result-of-B.Pharm-3rd-Sem-Supple.-Exam-DPSRU-2021.pdf";
import oldres150 from "./ResultsData/Result-of-B.Pharm-2nd-Sem-Supple.-Exam-DPSRU-2021.pdf";
import oldres151 from "./ResultsData/Result-of-B.Pharm-2nd-Sem-Supple.-Exam-DIPSAR-2021.pdf";
import oldres152 from "./ResultsData/Result-of-4th-Semester-Supple.-Exam-DIPSAR-February-2021.pdf";
import oldres153 from "./ResultsData/Result-of-M.Pharm-2nd-Sem-Supple.-MPB-DPSRU-March-2021.pdf";
import oldres154 from "./ResultsData/Result-of-BPT-2nd-Sem-Supple.-Exam-2018-Batch-DPSRU-March-2021-1.pdf";
import oldres155 from "./ResultsData/Result-of-B.Pharm-3rd-Sem-Supple.-Exam-DIPSAR-Feb.-2021.pdf";
import oldres156 from "./ResultsData/Result-of-B.Pharm-6th-Sem-Supple.-Exam-DPSRU-Feb.-2021.pdf";
import oldres157 from "./ResultsData/Result-of-B.Pharm-7th-Sem-Supple.-Exam-DPSRU-Feb.-2021.pdf";
import oldres158 from "./ResultsData/Result-of-B.Pharm-7th-Sem-Supple.-Exam-DIPSAR-Feb.-2021.pdf";
import oldres159 from "./ResultsData/Result-of-M.Pharm-2nd-Semester-Supple.-Exam-DPSRU-November-2020.pdf";
import oldres160 from "./ResultsData/Result-of-M.Pharm-1st-Sem-Supple.-Exam-DPSRU-November-2020.pdf";

// new data 9 files at 14/10/2023
import nnneww001 from "./ResultsData/Result-of-B.Pharm-7th-Semester-Old-Batch-DIPSAR-March-2022.pdf";
import nnneww002 from "./ResultsData/Result-of-B.Pharm-8th-Semester-Examination-DIPSAR-August-2023.pdf";
import nnneww003 from "./ResultsData/Result-of-B.Pharm-6th-Semester-Examination-DIPSAR-August-2023.pdf";
import nnneww004 from "./ResultsData/Result-of-B.Pharm-6th-Semester-Supple.-Examination-DIPSAR-August-2023-1.pdf";
import nnneww005 from "./ResultsData/Result-of-B.Pharm-6th-Semester-Supple.-Examination-SPS-August-2023.pdf";
import nnneww006 from "./ResultsData/Result-of-B.Pharm-6th-Semester-Examination-SPS-August-2023.pdf";
import nnneww007 from "./ResultsData/Result-of-B.Pharm-8th-Semester-Examination-SPS-August-2023.pdf";
import nnneww008 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-4th-Semester-Supplementary-Examinations-Aug-2023.pdf";
import nnneww009 from "./ResultsData/Result-of-B.-Sc.-Hons.-Sports-Science-4th-Semester-Examinations-Aug-2023.pdf";


//recent
import rec01 from "./ResultsData/Result-of-M.Pharm-2nd-Semester-Supple.-Exam.-Industrial-Pharmacy-August-2023.pdf";

// after live
import ResultOfBBACh4thsemsept from './ResultsData/Result of BBA- CH 4th Semester Examination, September-2023.PDF'
import ResultOfDmlt2ndsemsept from './ResultsData/Result of DMLT 2nd Semester Supple. Examination, September-2023.PDF'

import ResultOfDmlt2ndsemsept2 from './ResultsData/Result of DMLT 2nd Semester Examination, September-2023.PDF'
import ResultOfBscBiomedicalSciencesept from './ResultsData/Result of B.Sc. (Biomedical Science) 2nd Semester Examination, September-2023.PDF'
import ResultOfBmlt2ndsemsept from './ResultsData/Result of BMLT 2nd Semester Examination, September-2023.PDF'

import ResultOfBBAHC2ndSem from './ResultsData/Result of BBA-HC 2nd Semester Examination, Sept-2023.PDF'
import ResultOfBBACHSupplementaryExamination from './ResultsData/Result of BBA-CH 2nd Semester Supplementary Examination, Sept-2023.PDF'
import ResultOfBMLT2ndSemSupplementary from './ResultsData/Result  of BMLT 2nd Semester Supplementary Examination, Sept-2023.PDF'
import ResultOfMBAPet2ndSemSuppExam from './ResultsData/Result of MBA (PET) 2nd Semester Supplementary Examination, Sept-2023.PDF'
import revisedResult from './ResultsData/Revised Result of MBA-ITM 1st Semester Examination, March-2023.pdf'
import ResultOfBMLT4thsemExaminationsept from './ResultsData/Result of BMLT 4th Semester Examination, September -2023.pdf'
import ResultOfDMLT4thsemExaminationsept from './ResultsData/Result of DMLT 4th Semester Examination, September -2023.pdf'
import mscAim2ndsemexaminationsept2023 from './ResultsData/Result of M.Sc AIM 2nd Semester Examination, September -2023.pdf'
import resultofbscss2ndsemexamination from './ResultsData/Result of B. Sc. (Hons.) Sports Science 2nd Semester Examinations, Sep-2023.PDF'
import bpharmresult2ndsemsept from './ResultsData/RESULT OF B.PHARM 2ND SEMESTER SPS, SEPTEMBER 2023.pdf'
import bhparmsecondsemdipsar from './ResultsData/RESULT OF B.PHARM 2ND SEMESTER EXAMINATION DIPSAR, SEPTEMBER.pdf'
import mbapm2ndyearsemexaminsept2023 from './ResultsData/Result of MBA-PM 2nd Semester Examination, September -2023.pdf'
import mbahhm2ndsemexaminationsept2023 from './ResultsData/Result of MBA-HHM 2nd Semester Examination, September -2023.pdf'
import mph2ndsemexaminsept2023 from './ResultsData/Result of MPH 2nd Semester Examination, September-2023.pdf'
import bschonssopetscience2ndsem from './ResultsData/Result of B. Sc. (Hons.) Sports Science 2nd Semester Supple. Examinations, Sep-2023.PDF'
import resultofphdexaminationSUPOCT2023 from './ResultsData/Result of Pre-PhD Supplementary Examination, October -2023.pdf'
import resultodmbaitm2ndsemsept2023 from './ResultsData/Result of MBA-ITM 2nd Semester Examination, September -2023.pdf'
import resultodmbapm2ndsemsept2023 from './ResultsData/Result of MBA-PM 2nd Semester Supplementary Examination, September-2023.pdf'
import resultofBpharm4thsemandleexaminadipsarsept from './ResultsData/Result of B. pharm 4th Semester and L.E. Examination DIPSAR, September 2023.pdf'
import resultofbpharmleandsps from './ResultsData/Result of B. pharm 4th semester and L. E. Examination SPS, September 2023.pdf'
import mansisharmarevisedmpharmmay2022  from './ResultsData/Mansi Sharma Revised M.Pharm 1st Semester Result, May 2022.pdf'
import resultofbpharm4thsemsupsept from './ResultsData/Result of B.Pharm 4th Semester Supple. Examination SPS, September.pdf'
import resultofboharm2ndsemexamseptdipsar  from './ResultsData/Result of B.Pharm 2nd Semester Supple. Examination  DIPSAR, September 2023.pdf'
import boharmsuppspssprt2023  from './ResultsData/Result of B.Pharm 4th Semester Supple. Examination SPS, September 2023.pdf'
import Resulofmscsportsscice4thsemsemeam from './ResultsData/Result of M. Sc. Sports Science 4th Semester Examinations, Sep-2023.pdf'
import rofmptsm4thsemexamspet2023 from './ResultsData/Result of MPT (SM) 4th Semester Examinations, Sep-2023.pdf'
import mpharm4thsemexamniandipsardec2023 from './ResultsData/Result of M.Pharm 4th Semester Examination (DIPSAR), December 2023.pdf'
import mpharm4thsemtemexaminationspsdev2023 from './ResultsData/Result of M.Pharm 4th Semester Examination (SPS), December 2023.pdf'
import resultOfmscAipm4thsemexamdec2023 from './ResultsData/Result of M.Sc. AIPM 4th Semesteer Examination-December, 2023.pdf'
import resultOfMph4thsemexamdec2023 from './ResultsData/Result of MPH 4th Semesteer Examination-December, 2023.pdf'
import mbaPet4thsemexaminationdec2023 from './ResultsData/Result of MBA-PET 4th Semesteer Examination-December, 2023.pdf'
import mbapm4thsemexamdec2023 from './ResultsData/Result of MBA-PM 4th Semesteer Examination-December, 2023.pdf'
import mha4thsemexaminadec2023 from './ResultsData/Result of MHA 4th Semesteer Examination-December, 2023.pdf'
import drsesult4thsemdec2023 from './ResultsData/Result of Exe- DRS M.Pharm 4th Semester Examination, December 2023.pdf'
import bpharmayurveda2ndsemexam from './ResultsData/Result of B.Pharm (Ayurveda) 2nd Semester Examination, October -2023.pdf'
import bpharmayurveda2ndsemsupp from './ResultsData/Result of B.Pharm (Ayurveda) 2nd Semester Supplementary Examination, October -2023.pdf'
import resultofbpt2ndsemexaminationoct2023 from './ResultsData/Result of BPT 2nd Semester Examination, October -2023.pdf'
import resultofbpt4thsemexamoct2023 from './ResultsData/Result of BPT 4th Semester Examination, October -2023.pdf'
import resultofbpharmayrveda4thsemOct2023 from './ResultsData/Result of B.Pharm (Ayurveda) 4th Semester Examination, October -2023.pdf'
import mpharmspsoct2023 from './ResultsData/Result of M. Pharm SPS October 2023.pdf'
import mpharmDipsaroct2023 from './ResultsData/Result of M. Pharm DIPSAR October 2023.pdf'
import resultofDMYSbatchII1stTrimester from './ResultsData/Result of DMYS Batch-II 1st Trimester Examination, October-2023.pdf'
import resultofdmysbatch2ndtrinmen from './ResultsData/Result of DMYS Batch-II 2nd Trimester Examination, October-2023.pdf'
import resultofdmysbatch3rdtrinmen from './ResultsData/Result of DMYS Batch-II 3rd Trimester Examination, October-2023.pdf'
import resultofmscsportscience2ndsem from './ResultsData/Result of M.Sc Sports Sciences 2nd Semester Examinations, Sep-2023.pdf'
import resultofdmysbatch1trim3rd from './ResultsData/Resuslt of DMYS Batch-I 3rd Trimester Examination, October-2023.pdf'
import mptsportsmedicinecarandpulper2ndem from './ResultsData/Result of MPT (Sports Medicine,Cardiovascular and Pulmonary,Pediatrics) 2nd Semester Examinations, Sep-2023.pdf'
import resofbpt8thsemdec23 from './ResultsData/Result of BPT 8th Semester Examinations, Dec-2023.pdf'
import resofbpt7thsemdec23sup from './ResultsData/Result of BPT 7th Semester Supplementary Examinations, Dec-2023.pdf'
import mpharmindustrialPhar2ndsemsupexam from './ResultsData/Result of M.Pharm (Industrial Pharmacy) 2nd semester Supple. Exam , September-2023.pdf'
import mbapm3rdsemsuppdec23 from './ResultsData/MBA-PM 3rd Semester Supple.  Examination, December -2023.pdf'
import resultofDphar2ndyearsuppoldschemede23 from './ResultsData/Result of D.Pharm. 2nd Year Supplementary (Old Scheme) Examination, December -2023.pdf'
import dPharm2ndYearsupppexamDec23 from './ResultsData/Result of D.Pharm. 2nd Year Supplementary Examination December -2023.pdf'
import ResultofDpharm2ndyearanuallexamdec2023 from './ResultsData/Result of D.Pharm. 2nd Year Annual Examination December -2023.pdf'
import resultofdpharm1styearsupexamdec2023 from './ResultsData/Result of D.Pharm. 1st Year Supplementary Examination December -2023.pdf'
import resultoSaya2ndsemsuppexamdec23 from './ResultsData/Result of SAYA 2nd Semester Supplementary Examinations, Dec-2023.pdf'
import resultoSaya1stsemsuppexamdec23 from './ResultsData/Result of SAYA 1st Semester Supplementary Examinations, Dec-2023.pdf'
import resultoMMC1stsemsuppexamdec23 from './ResultsData/Result of MMC 1st Semester Supplementary Examinations, Dec-2023.pdf'
import resultoMMC2NDsemsuppexamdec23 from './ResultsData/Result of MMC 2nd Semester Supplementary Examinations, Dec-2023.pdf'
import ResultOfBpharm5thsemexamDipsarjan24 from './ResultsData/Result of B.Pharm 5th Semester Examination DIPSAR, January 2024.pdf'
// import resultOf5thsembpharmExamspsjan24 from './ResultsData/Result of B.Pharm 5th Semester Examination SPS, January 2024.pdf'


// import resultOf5thsembpharmExamspsjan24 from './ResultsData/Result of B.Pharm 5th Semester Examination (SPS), January 2024.pdf'
import resultOf5thsembpharmExamspsjan24 from './ResultsData/Result of B.Pharm 5th Semester Examination (SPS), January 2024_2.pdf'



import roBPharm5thsemsuppExamdipsarspsjan24 from './ResultsData/Result of B.Pharm 5th Semester Supple. Examination (DIPSAR & SPS), January 2024.pdf'
import bpharm3rdsemsuppexamres24 from './ResultsData/Result of B.Pharm 3rd Semester Supple. SPS, January 2024.pdf'
import bpharm3rdsemsuppexamresdipsar24 from './ResultsData/Result of B.Pharm 3rd Semester Supple. L.E DIPSAR, January 2024.pdf'
import resultofBpharm3rdsemsuppDipsarjan24 from './ResultsData/Result of B.Pharm 3rd Semester Supple. DIPSAR, January 2024.pdf'
import bpharm3rdsemdipsarresfeb24 from './ResultsData/Result of B.Pharm 3rd Semester Examination DIPSAR, January 2024.pdf'
import Resultofbphar3rdsemspsjan24 from './ResultsData/Result of B.Pharm 3rd Semester Examination SPS, January 2024.pdf'
import resultofbpharm3rdsemleexamspsjan24 from './ResultsData/Result of B.Pharm 3rd Semester L.E Examination SPS, January 2024.pdf'
import resultofbpharm3rdsemleexamdipsarjan24 from './ResultsData/Result of B.Pharm 3rd Semester L.E Examination DIPSAR, January 2024.pdf'
import revisedResultofBpt4thsemexamoct24 from './ResultsData/Revised Result of BPT 4th Semester Examination, October-2024 (Ashima Gupta).pdf'
import resultOfbpharm7thsemsuppexamspsjan24 from './ResultsData/Result of B.Pharm 7th Semester Supple. Examination SPS, January 2024.pdf'
import bpharm7thsemexamdipsarjan24 from './ResultsData/Result of B.Phatm 7th Semester Examination DIPSAR, January 2024.pdf'
import Bpharm7thsemexamspsjan24 from './ResultsData/Result of B.Pharm 7th Semester Examination SPS, January 2024.pdf'
import resultofBharmayrveda1tsemexanfeb24 from './ResultsData/Result of B.Pharm(Ayurveda) (1st Semester Examination, February- 2024).pdf'
import bpharmayrveda1stsemsuppexamfeb24 from './ResultsData/Result of B. Pharm(Ayurveda) (1st Semester Supplementary Examination , February- 2O24.pdf'
import bbaHc1stsemexamfeb24 from './ResultsData/Result of BBA-HC 1st Semester Examination, February-2024.pdf'
import dmlt1stsemsuppexamfeb24 from './ResultsData/Result of DMLT 1st Semester Supplementary Examination, February-2024.pdf'
import dmlt1stsemexamfeb24 from './ResultsData/Result of DMLT 1st Semester Examination, February-2024.pdf'
import dmlt3rdsemexamfeb24 from './ResultsData/Result of DMLT 3rd Semester Examination, February-2024.pdf'
import bmlt5thsemexamfeb24 from './ResultsData/Result of BMLT 5th Semester Examination, February-2024.pdf'
import resultofBpt1stsemsuppexamfeb24 from './ResultsData/Result of BPT 1st Semester Supplementary Examinations, Feb-2024.pdf'
import resultofbschons5thsemexamfeb24 from './ResultsData/Result of B. Sc. (Hons.) Sports Science 5th Semester Examinations, Feb-2024.pdf'
import resuofbschonssportsscience5thsemsuppexam from './ResultsData/Result of B. Sc. (Hons.) Sports Science 5th Semester Supplementary Examinations, Feb-2024.pdf'
import resultofbpharm1stsemexamspsjan24 from './ResultsData/Result of B.Pharm 1st Semester Examination (SPS), January 2024.pdf'
import resultofbpharm1stsemexamdipsarjan24 from './ResultsData/Result of B.Pharm 1st Semester Examination (DIPSAR), January 2024.pdf'
import corigendrumresultbpharm3rdsemdipsarlejan24 from './ResultsData/Corrigendum Result of B.Pharm 3rd Semester Examination (DIPSAR) L.E, January 2024.pdf'
import corigendrumresultbpharm3rdsemdipsajan24 from './ResultsData/Corrigendum Result of B.Pharm 3rd Semester Examination (DIPSAR), January 2024.pdf'
import corigendrumresultbpharm3rdsemspsjan24 from './ResultsData/Corrigendum Result of B.Pharm 3rd Semester (SPS), January 2024.pdf'
import corigendrumresultbpharm3rdsemlejan24 from './ResultsData/Corrigendum Result of B.Pharm 3rd Semester Examination Lateral Entry, January 2024.pdf'
import bpharm1stsemsuppexamdipsarjan24 from './ResultsData/Result of B.Pharm 1st Semester Supplementary Examination (DIPSAR), January 2024.pdf'
import resultofBpharm1stsemsuppexamspsjan24 from './ResultsData/Result of B.Pharm 1st Semester Supplementary Examination (SPS), January 2024.pdf'
import resultofbscHonssportsscience1stsemexamfeb24 from './ResultsData/Result of  B. Sc.(Hons.) Sports Science 1st Semester Examination, February-2024.pdf'
import bpt1stsemexamfeb24 from './ResultsData/Result of BPT 1st Semester Examination, February- 2024.pdf'
import resultofBschonssportsscience1stsemsuppexamfeb24 from './ResultsData/Result of B. Sc.(Hons.) Sports Science 1st semester supplementary Examination, February- 2024.pdf'
import resultofBPT7thsemeamfeb24 from './ResultsData/Result of BPT 7th Semester Examination, February- 2024.pdf'
import bschonsbiomedicalscience3rdsemexamfeb24 from './ResultsData/Result of B.Sc.(Hons) Biomedical Science 3rd Semester Examination, February- 2024.pdf'
import resultofbpt7thsemsuppexamfeb24 from './ResultsData/Result of BPT 7th Semester Supplemenatary Examination, February- 2024.pdf'
import mptsportsmedicinepediatricscariovascularandspullmonary1stsemexamfeb24 from './ResultsData/Result of MPT (Sports Medicine, Pediatrics, Cardiovascular and Pulmonary) 1st Semester Examinations, February-2024.pdf'
import resultofMPTsportsmedicinepediaricscardiandpill3rdsemexammar24 from './ResultsData/Result of MPT (Sports Medicine, Pediatrics, Cardiovascular and Pulmonary) 3rd Semester Examinations, March-2024.pdf'
import resultofBBahc3rdsemexamfeb24 from './ResultsData/Result of BBA-HC 3rd Semester Examination, February -2024.pdf'
import resultofBpharm3rdswemsuppledipsarjan24 from './ResultsData/Result of B.Pharm 3rd Semester Supple. L.E (DIPSAR), January 2024.pdf'
import resultofBMLT3rdsemexmafeb24 from './ResultsData/Result of BMLT 3rd Semester Examination, February-2024.pdf'
import resultofBMLT3rdsemexmaSubbbbfeb24 from './ResultsData/Result of BMLT 3rd Semester Supplemenatry Examination, February-2024.pdf'
import ResultofMPH3rdsemexamfeb24 from './ResultsData/Result of MPH 3rd Semester Examination , February 2024.pdf'
import resultOfbbaCh3rdsemsuppexamfeb24 from './ResultsData/Result of BBA-CH 3rd Semester Supplementary examination, February-2024.pdf'
import resultofBBACh1stsemsuppexamfeb24 from './ResultsData/Result of BBA-CH 1st Semester Supplementary examination, February -2024.pdf'
import resultofBscBiomedical1stsemsuppexamfeb24 from './ResultsData/Result of B.Sc. Biomedical 1st Semester Supplementary Examination, February-2024.pdf'
import resultofBBAch5thsemexamfeb24 from './ResultsData/Result of BBA-CH 5th Semester Examination, February-2024.pdf'
import resultofBscbio1stexamfeb24 from './ResultsData/Result of B.Sc. Biomedical 1st Semester Examination, February-2024.pdf'
import resultofMBAhhm1stsemexamfeb24 from './ResultsData/Result of MBA-HHM 1st Semester Examination, February-2024.pdf'
import resultofBmlt1stsemexamfeb24 from './ResultsData/Result of BMLT 1st Semester Examination, February-2024.pdf'
import resultofMPT1stsemexamfeb24 from './ResultsData/Result of MPH 1st Semester Examination, February-2024.pdf'
import resultofmbapm3rdsemexamfeb24 from './ResultsData/Result of MBA-PM 3rd Semester Examination, February-2024.pdf'
import resultofmbaitm3rdsemexamfeb24 from './ResultsData/Result of MBA-ITM 3rd Semester Examination, February-2024.pdf'
import resultofMBAhhm3rdsemexamfeb24 from './ResultsData/Result of MBA-HHM 3rd Semester Examinations, Feb-2024.pdf'
import resultOfMscspoetss3rdsemexammar24 from './ResultsData/Result of  M.Sc. Sports Sciences 3rd Semester Examination, March- 2024.pdf'
import Bpt5thsemmainandsuppexammar24 from './ResultsData/Result of BPT 5th Semester (Main & Supplementary) Examination, March- 2024.pdf'
import resultofbmlt1stsemsuppoldschemeexamfeb24 from './ResultsData/Result of BMLT 1st Semester Supplementary (Old Scheme) Examination, February -2024.pdf'
import resultofbmlt1stsemsuppexamfeb24 from './ResultsData/Result of BMLT 1st Semester Supplementary examination, February-2024.pdf'
import bscsopetsscicene3rdsemsuppexammar24saurabh from './ResultsData/Result of  B. Sc. (Hons.)  Sports Sciences 3rd Semester Supplementary Examination, March- 2024 (Saurabh Kumar Gautam).pdf'
import bschonssportsscicene3rdsemexammar24 from './ResultsData/Result of  B. Sc. (Hons.)  Sports Sciences 3rd Semester Examination, March- 2024.pdf'
import resultofBpharmayruveda5thsemexamfeb24 from './ResultsData/Result of B. Pharm Ayurveda 5th Sem Examination February 2024.pdf'
import bscsportsscienceresult3rdsemsuppexam from './ResultsData/Result of B.Sc.(Hons.) Sports Science 3rd Semester Supplementary Examination, March-2024.pdf'
import resultofbpt3rdsemmainandsuppexammarch24 from './ResultsData/Result of  BPT 3rd Semester (Main & Supplementary) Examination, March-2024.pdf'
import resultofbpharmayrveda3rdsemexamapr24 from './ResultsData/Result of B. Pharm (Ayurveda) 3rd Semester Examination, April-2024.pdf'
import resultofbpharmayrveda3rdSuppsemexamapr24 from './ResultsData/Result of B. Pharm (Ayurveda) 3rd Semester Supplementary Examination, April-2024.pdf'
import ResultofMBApm3rdsemestersuppexamfeb24 from './ResultsData/Result of MBA-PM 3rd Semester Supplementary Examinations, Feb-2024.pdf'
import resultofMscaimsemesterexamapr24 from './ResultsData/Result of M. Sc. AIM 1st Semester Examinations, April-2024.pdf'
import resultofMBAITM1stsemexamfeb24 from './ResultsData/Result of MBA-ITM 1st Semester Examinations, Feb-2024.pdf'
import resultofMScaim3rdsemexamapr24 from './ResultsData/Result of M. Sc. AIM 3rd Semester Examinations, April-2024.pdf'
import ResultofMBApm1stsemexamfeb24 from './ResultsData/Result of MBA-PM 1st Semester Examinations, Feb-2024.pdf'
import Mpharm1stsemdipsarmainandsuppexamapr4 from './ResultsData/Result of M. Pharm 1st Semester DIPSAR (Main and Supple.) Examination April-2024.pdf'
import mpharm1stsemSPSmainandsuppexamApr24 from './ResultsData/Result of M. Pharm 1st Semester SPS (Main and Supple.) Examination April-2024.pdf'
import Draandexdrsmphar1stsemspsexamapr24 from './ResultsData/Result of M. Pharm 1st Semester SPS (DRA & Ex- DRS) Examination, April 2024.pdf'
import resultofMpharm3rdsemspstheoryexamapr24 from './ResultsData/Result of M. Pharm 3rd Semester SPS Theory Examination April- 2024.pdf'
import resultofMpharm3rdsemdipdarthoexamapr24 from './ResultsData/Result of M. Pharm 3rd Semester DIPSAR Theory Examination, April- 2024.pdf'
import mphrmpharmaceutics3rdsemexamapr24 from './ResultsData/Result of M.Pharm Pharmaceutics 3rd Semester Examination, April 2024.pdf'
import resultofDpharm2ndyearsuppOldschemeexammay24 from './ResultsData/Result of D.Pharm. 2nd Year Supplementary (Old Scheme) Examination, May-2024.pdf'
import resultofDpharm2ndyearannualexammay24 from './ResultsData/Rsult of D.Pharm. 2nd Year Annual Examination, May-2024.pdf'
import resultoBpharm7thsemsuppexaDipsarMay24 from './ResultsData/Result of B.Pharm 7th Semester Supplementary Examination (DIPSAR), May 2024.pdf'
import resultofBscHonsSopetsScience6thsemexammay24 from './ResultsData/Result of B. Sc. (Hons.) Sports Science 6th Semester Examinations, May-2024.pdf'
import resultofBpharm8thsemexamoldbatchdipsarmay24 from './ResultsData/Result of B.Pharm 8th Semester Examination Old Batch (DIPSAR), May 2024.pdf'
import resultofphar8thsemexmDipsarmay24 from './ResultsData/Result of B.Pharm 8th Semester Examination (DIPSAR), May 2024.pdf'
import resultof8thsemexamdpsrumay24 from './ResultsData/Result of B.Pharm 8th Semester Examination (DPSRU), May 2024.pdf'
import resultofbbach6thsemexamjune from './ResultsData/Result of BBA-CH 6th Semester Examination, June-2024.pdf'
import resultofbpt7thsemsuppexamjune24 from './ResultsData/Result of BPT 7th Semester Supplementary Examination, June-2024.pdf'
import resultoofbschonsspotsscienc6thsemsuppexammay24 from './ResultsData/Result of B.Sc.(Hons.) Sports Science 6th Semester Supplementary Examination, May-2024.pdf'
import resultofbpt1styearsuppexamjune24 from './ResultsData/Result of BPT 1st Year Supplementary Examination, June-2024.pdf'
import resultofDpharm1styearannualexammay24 from './ResultsData/Result of D.Pharm. 1st Year Annual Examination, May-2024.pdf'
import resultofDpharm1styearsuppexammay24 from './ResultsData/Result of D.Pharm. 1st Year Supplementary Examination, May-2024.pdf'
import resultofBpharm3rdsemsuppexamdpsrumay24 from './ResultsData/Result of B.Pharm 3rd Semester Supplementary Examination (DPSRU), May 2024.pdf'
import rofbphar5thsemsuppexamdpsrumay24 from './ResultsData/Result of B.Pharm 5th Semester Supplementary Examination (DPSRU), May 2024.pdf'
import resultofbpharm3rdsemsuppexamlateralentrydipsarmay24 from './ResultsData/Result of B.Pharm 3rd Semester Supplementary Examination Lateral Entry (DIPSAR), May 2024.pdf'
import resultofbpharm5thsemsuppexamdipsarmay24 from './ResultsData/Result of B.Pharm 5th Semester Supplementary Examination (DIPSAR), May 2024.pdf'
import resultofBphamrm3rdsemsuppexam2021batchdipsarmay24 from './ResultsData/Result of B.Pharm 3rd Semester Supplementary Examination 2021 Batch (DIPSAR), May 2024.pdf'
import resultofprephdexammay24 from './ResultsData/Result of Pre Ph.D Examination, May-2024.pdf'
import resultofmscaim1stsemsuppexamapr24 from './ResultsData/Result of M.Sc. AIM 1st Semester Supplementary Examination, April-2024.pdf'
import resultofbpt5thsemsuppexamjul24 from './ResultsData/Result of BPT 5th Semester Supplementary Examinations, July-2024.pdf'
import resultofbpharm8thsemexamspsjuly24 from './ResultsData/Result of B. Pharm 8th Semester Exams SPS, July 2024.pdf'
import resultofbpharm8thsemexamDipsarjuly24 from './ResultsData/Result of B. Pharm 8th Semester Exams DIPSAR, July 2024.pdf'
import resultofbpharm1stsemsuppexamspsmay24 from './ResultsData/Result of B. Pharm 1st Semester Supple. Exams SPS, May 2024.pdf'
import resultofbpharm1stsemsuppexamDipsarmay24 from './ResultsData/Result of B. Pharm 1st Semester Supple. Exams DIPSAR, May  2024.pdf'
import resultofbpharm6thsemsuppexamdipsarjune24 from './ResultsData/Result of B. Pharm 6th Semester Supple. Exams DIPSAR, June 2024.pdf'
import resultofbpharm6thsemsuppexamSPSjune24 from './ResultsData/Result of B. Pharm 6th Semester Supple. Exams SPS, June 2024.pdf'
import bpharm6thsemexamspsjun24 from './ResultsData/Result of B. Pharm 6th Semester Exams SPS, June 2024.pdf'
import bpharm6thsemexamDipsarjun24 from './ResultsData/Result of B. Pharm 6th Semester Exams DIPSAR, June 2024.pdf'
import bpharm2ndsemSuppexamSPSjun24 from './ResultsData/Result of B. Pharm 2nd Semester Supple. Exams SPS, June 2024.pdf'
import bpharm2ndsemSuppexamDIPSARjun24 from './ResultsData/Result of B. Pharm 2nd Semester Supple. Exams DIPSAR, June 2024.pdf'
import bpharm2ndsemexamDIPSARjun24 from './ResultsData/Result of B. Pharm 2nd Semester Exams DIPSAR, June 2024.pdf'
import bpharm2ndsemexamSPSjun24 from './ResultsData/Result of B. Pharm 2nd Semester Exams SPS, June 2024.pdf'
import Bpharm4thsemsuppexamspsjune24 from './ResultsData/Result of B. Pharm 4th Semester Supple. Exams SPS, June 2024.pdf'
import resultofBpharm4thsemsuppexamdipsarjune24 from './ResultsData/Result of B. Pharm 4th Semester Supple. Exams DIPSAR, June 2024.pdf'
import resultofBpharm4thsemexamspsjune24 from './ResultsData/Result of B. Pharm 4th Semester Exams SPS, June 2024.pdf'
import resultofBpharm4thsemexamdipsarjune24 from './ResultsData/Result of B. Pharm 4th Semester Exams DIPSAR, June 2024.pdf'
import resultofBpharmayurveda2ndsemsuppexamjuly24 from './ResultsData/Result of B. Pharm (Ayurveda) 2nd Semester Supple. Exam, July- 2024.pdf'
import bpharmayurveda2ndsemexamjuly24 from './ResultsData/Result of B. Pharm (Ayurveda) 2nd Semester Exam, July- 2024.pdf'
import resultofbschonssportsscience4thsemmainandsuppexamjuly24 from './ResultsData/Result of B. Sc. (Hons.) Sports Science 4th Semester (Main & Supplementary) Examinations, July-2024.pdf'
import resultofbpt8thsemmainandsuppexamjuly24 from './ResultsData/Result of BPT 8th Semester (Main & Supplementary) Examinations, July-2024.pdf'
import bpt4thsemmainandsuppexamjuly24 from './ResultsData/Result of BPT 4th Semester (Main & Supplementary) Examinations, July-2024.pdf'
import resultofsportscience6thsemexajuly24Lakshaybaradia from './ResultsData/Result of B. Sc. (Hons.) Sports Science 6th Semester Examinations, July-2024 (Lakshay Baredia).pdf'
import resulofBpt8thsemexamjuly24afsal from './ResultsData/Result of BPT 8th Semester Examinations, July-2024 (AFSAL).pdf'
import resultofBPT6thsemmainandsuppexamjuly24 from './ResultsData/Result of BPT 6th Semester (Main & Supplementary) Examinations, July-2024.pdf'
import resultofBPT2ndsemmainandsuppexamjuly24 from './ResultsData/Result of BPT 2nd Semester (Main & Supplementary) Examinations, July-204.pdf'
import bschonsss2ndsemmainandsuppexamjuly24 from './ResultsData/Result of B. Sc. (Hons.) Sports Science 2nd Semester (Main & Supplementary) Examinations, July-2024.pdf'
import resultofMptsmcpp4thsemexamjuly24 from './ResultsData/Result of MPT (Sports Medicine, Cardiovascular and Pulomonary & Pediatrics) 4th Semester Examinations, July-2024.pdf'
import resultofMptsmcpp2ndsemexamjuly24 from './ResultsData/Result of MPT (Sports Medicine, Cardiovascular and Pulomonary & Pediatrics) 2nd Semester Examinations, July-2024.pdf'
import resultofDMLT2ndsemexamjul24 from './ResultsData/Result of DMLT 2nd Semester Examination, July-2024.pdf'
import resultofDMlt2ndsemsuppexamjul24 from './ResultsData/Result of DMLT 2nd Semester Supplementary Examination, July-2024.pdf'
import resultofBMLT2ndsemsuppexamjuly24 from './ResultsData/Result of BMLT 2nd Semester Supplementary Examination, July-2024.pdf'
import resultofBMLT2ndsemexamjuly24 from './ResultsData/Result of BMLT 2nd Semester Examination, July-2024.pdf'
import reofBpharmayurveda6thsemexamaug24 from './ResultsData/Result of B. Pharm (Ayurveda) 6th semester Exams, August 2024.pdf'
import resultofMpharm3rdsemsuppexamdraspsoct24 from './ResultsData/Result of M.Pharm 3rd Semester Supplementary Examination (Drug Regulatory Affairs) SPS, October 2024.pdf'
import bbahc2ndsemsuppexamjul24 from './ResultsData/Result of BBA-HC 2nd Semester Supplementary Examination, July-2024.pdf'
import resultofBacbiomedicalscience4thsemexamaug24 from './ResultsData/Result of B.Sc. Biomedical Science 4th Semester Examination, August-2024.pdf'
import bscbiomescience2ndsemsuppexamaug24 from './ResultsData/Result of B.Sc. Biomedical Science 2nd Semester Supplementary  Examination, August-2024.pdf'
import bbahcendsmejul24 from './ResultsData/Result of BBA-HC 2nd Semester Examination, July-2024.pdf'
import resultofBSCbiomedicalscience2ndsemexamaug24 from './ResultsData/Result of B.Sc. Biomedical Science 2nd Semester Examination, August-2024.pdf'
import resultofBBAHC4thsemexamjuly24 from './ResultsData/Result of BBA-HC 4th Semester Examination, July-2024.pdf'
import resultofBMLT4thsemexamjuly24 from './ResultsData/Result of BMLT 4th Semester Examination, July-2024.pdf'
import resultofBMLT6thsemexamspept24 from './ResultsData/Result of BMLT 6th Semester Examination, September-2024.pdf'
import resultofMSCsportsscience4thsemexamjul24 from './ResultsData/Result of M. Sc. Sports Science 4th Semester Examinaiton, July-2024.pdf'
import fResutofMPT2ndsemexamaug24 from './ResultsData/Result of MPH 2nd Semester Examinations, Aug-2024.pdf'
import resultofMBAHHM2ndsemsuppexamaug24 from './ResultsData/Result of MBA (HHM) 2nd Semester Supplementary Examination, August-2024.pdf'
import resultofDMLT4hsemexamsept24 from './ResultsData/Result of DMLT 4th Semester Examination, September-2024.pdf'
import mbaPM2ndsemexamaug24 from './ResultsData/Result of MBA (PM) 2nd Semester Examination, August-2024.pdf'
import MBAITM2ndsemexamaug24 from './ResultsData/Result of MBA (ITM) 2nd Semester Examination, August-2024.pdf'
import resultofMBAHHM2ndsemexamaug24 from './ResultsData/Result of MBA (HHM) 2nd Semester Examination, August-2024.pdf'
import resultofBpharmaurveda4thsemexamsept24 from './ResultsData/Result of B.Pharm (Ayurveda) 4th Semester Examination, September 2024.pdf'


function Results() {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        EXAMINATION-RESULTS
      </h2>

      <div className="results-data-link-container">
        {/* pdfLink */}
        <ul>
        <li>
            <a href={resultofBpharmaurveda4thsemexamsept24}>
            Result of B.Pharm (Ayurveda) 4th Semester Examination, September 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMBAHHM2ndsemexamaug24}>
            Result of MBA (HHM) 2nd Semester Examination, August-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={MBAITM2ndsemexamaug24}>
            Result of MBA (ITM) 2nd Semester Examination, August-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={mbaPM2ndsemexamaug24}>
            Result of MBA (PM) 2nd Semester Examination, August-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofDMLT4hsemexamsept24}>
            Result of DMLT 4th Semester Examination, September-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMBAHHM2ndsemsuppexamaug24}>
            Result of MBA (HHM) 2nd Semester Supplementary Examination, August-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={fResutofMPT2ndsemexamaug24}>
            Result of MPH 2nd Semester Examinations, Aug-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMSCsportsscience4thsemexamjul24}>
            Result of M. Sc. Sports Science 4th Semester Examinaiton, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBMLT6thsemexamspept24}>
            Result of BMLT 6th Semester Examination, September-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBMLT4thsemexamjuly24}>
            Result of BMLT 4th Semester Examination, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBBAHC4thsemexamjuly24}>
            Result of BBA-HC 4th Semester Examination, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBSCbiomedicalscience2ndsemexamaug24}>
            Result of B.Sc. Biomedical Science 2nd Semester Examination, August-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bbahcendsmejul24}>
            Result of BBA-HC 2nd Semester Examination, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bscbiomescience2ndsemsuppexamaug24}>
            Result of B.Sc. Biomedical Science 2nd Semester Supplementary  Examination, August-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBacbiomedicalscience4thsemexamaug24}>
            Result of B.Sc. Biomedical Science 4th Semester Examination, August-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bbahc2ndsemsuppexamjul24}>
            Result of BBA-HC 2nd Semester Supplementary Examination, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
         
        <li>
            <a href={resultofMpharm3rdsemsuppexamdraspsoct24}>
            Result of M.Pharm 3rd Semester Supplementary Examination (Drug Regulatory Affairs) SPS, October 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={reofBpharmayurveda6thsemexamaug24}>
            Result of B. Pharm (Ayurveda) 6th semester Exams, August 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBMLT2ndsemexamjuly24}>
            Result of BMLT 2nd Semester Examination, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBMLT2ndsemsuppexamjuly24}>
            Result of BMLT 2nd Semester Supplementary Examination, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofDMlt2ndsemsuppexamjul24}>
            Result of DMLT 2nd Semester Supplementary Examination, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofDMLT2ndsemexamjul24}>
            Result of DMLT 2nd Semester Examination, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMptsmcpp2ndsemexamjuly24}>
            Result of MPT (Sports Medicine, Cardiovascular and Pulomonary & Pediatrics) 2nd Semester Examinations, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMptsmcpp4thsemexamjuly24}>
            Result of MPT (Sports Medicine, Cardiovascular and Pulomonary & Pediatrics) 4th Semester Examinations, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bschonsss2ndsemmainandsuppexamjuly24}>
            Result of B. Sc. (Hons.) Sports Science 2nd Semester (Main & Supplementary) Examinations, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBPT2ndsemmainandsuppexamjuly24}>
            Result of BPT 2nd Semester (Main & Supplementary) Examinations, July-204
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBPT6thsemmainandsuppexamjuly24}>
            Result of BPT 6th Semester (Main & Supplementary) Examinations, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resulofBpt8thsemexamjuly24afsal}>
            Result of BPT 8th Semester Examinations, July-2024 (AFSAL)
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofsportscience6thsemexajuly24Lakshaybaradia}>
            Result of B. Sc. (Hons.) Sports Science 6th Semester Examinations, July-2024 (Lakshay Baredia)
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bpt4thsemmainandsuppexamjuly24}>
            Result of BPT 4th Semester (Main & Supplementary) Examinations, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpt8thsemmainandsuppexamjuly24}>
            Result of BPT 8th Semester (Main & Supplementary) Examinations, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbschonssportsscience4thsemmainandsuppexamjuly24}>
            Result of B. Sc. (Hons.) Sports Science 4th Semester (Main & Supplementary) Examinations, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bpharmayurveda2ndsemexamjuly24}>
            Result of B. Pharm (Ayurveda) 2nd Semester Exam, July- 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBpharmayurveda2ndsemsuppexamjuly24}>
            Result of B. Pharm (Ayurveda) 2nd Semester Supple. Exam, July- 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBpharm4thsemexamdipsarjune24}>
            Result of B. Pharm 4th Semester Exams DIPSAR, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBpharm4thsemexamspsjune24}>
            Result of B. Pharm 4th Semester Exams SPS, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBpharm4thsemsuppexamdipsarjune24}>
            Result of B. Pharm 4th Semester Supple. Exams DIPSAR, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={Bpharm4thsemsuppexamspsjune24}>
            Result of B. Pharm 4th Semester Supple. Exams SPS, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bpharm2ndsemexamDIPSARjun24}>
            Result of B. Pharm 2nd Semester Exams DIPSAR, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bpharm2ndsemexamSPSjun24}>
            Result of B. Pharm 2nd Semester Exams SPS, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bpharm2ndsemSuppexamDIPSARjun24}>
            Result of B. Pharm 2nd Semester Supple. Exams DIPSAR, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bpharm2ndsemSuppexamSPSjun24}>
            Result of B. Pharm 2nd Semester Supple. Exams SPS, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bpharm6thsemexamDipsarjun24}>
            Result of B. Pharm 6th Semester Exams DIPSAR, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bpharm6thsemexamspsjun24}>
            Result of B. Pharm 6th Semester Exams SPS, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharm6thsemsuppexamSPSjune24}>
            Result of B. Pharm 6th Semester Supple. Exams SPS, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharm6thsemsuppexamdipsarjune24}>
            Result of B. Pharm 6th Semester Supple. Exams DIPSAR, June 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharm1stsemsuppexamDipsarmay24}>
            Result of B. Pharm 1st Semester Supple. Exams DIPSAR, May  2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharm1stsemsuppexamspsmay24}>
            Result of B. Pharm 1st Semester Supple. Exams SPS, May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharm8thsemexamDipsarjuly24}>
            Result of B. Pharm 8th Semester Exams DIPSAR, July 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharm8thsemexamspsjuly24}>
            Result of B. Pharm 8th Semester Exams SPS, July 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpt5thsemsuppexamjul24}>
            Result of BPT 5th Semester Supplementary Examinations, July-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofmscaim1stsemsuppexamapr24}>
            Result of M.Sc. AIM 1st Semester Supplementary Examination, April-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofprephdexammay24}>
            Result of Pre Ph.D Examination, May-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBphamrm3rdsemsuppexam2021batchdipsarmay24}>
            Result of B.Pharm 3rd Semester Supplementary Examination 2021 Batch (DIPSAR), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharm5thsemsuppexamdipsarmay24}>
            Result of B.Pharm 5th Semester Supplementary Examination (DIPSAR), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharm3rdsemsuppexamlateralentrydipsarmay24}>
            Result of B.Pharm 3rd Semester Supplementary Examination Lateral Entry (DIPSAR), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={rofbphar5thsemsuppexamdpsrumay24}>
            Result of B.Pharm 5th Semester Supplementary Examination (DPSRU), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBpharm3rdsemsuppexamdpsrumay24}>
            Result of B.Pharm 3rd Semester Supplementary Examination (DPSRU), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofDpharm1styearsuppexammay24}>
            Result of D.Pharm. 1st Year Supplementary Examination, May-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofDpharm1styearannualexammay24}>
            Result of D.Pharm. 1st Year Annual Examination, May-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpt1styearsuppexamjune24}>
            Result of BPT 1st Year Supplementary Examination, June-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultoofbschonsspotsscienc6thsemsuppexammay24}>
            Result of B.Sc.(Hons.) Sports Science 6th Semester Supplementary Examination, May-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpt7thsemsuppexamjune24}>
            Result of BPT 7th Semester Supplementary Examination, June-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbbach6thsemexamjune}>
            Result of BBA-CH 6th Semester Examination, June-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultof8thsemexamdpsrumay24}>
            Result of B.Pharm 8th Semester Examination (DPSRU), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofphar8thsemexmDipsarmay24}>
            Result of B.Pharm 8th Semester Examination (DIPSAR), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBpharm8thsemexamoldbatchdipsarmay24}>
            Result of B.Pharm 8th Semester Examination Old Batch (DIPSAR), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofBscHonsSopetsScience6thsemexammay24}>
            Result of B. Sc. (Hons.) Sports Science 6th Semester Examinations, May-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultoBpharm7thsemsuppexaDipsarMay24}>
            Result of B.Pharm 7th Semester Supplementary Examination (DIPSAR), May 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofDpharm2ndyearannualexammay24}>
            Rsult of D.Pharm. 2nd Year Annual Examination, May-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofDpharm2ndyearsuppOldschemeexammay24}>
            Result of D.Pharm. 2nd Year Supplementary (Old Scheme) Examination, May-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={mphrmpharmaceutics3rdsemexamapr24}>
            Result of M.Pharm Pharmaceutics 3rd Semester Examination, April 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMpharm3rdsemdipdarthoexamapr24}>
            Result of M. Pharm 3rd Semester DIPSAR Theory Examination, April- 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMpharm3rdsemspstheoryexamapr24}>
            Result of M. Pharm 3rd Semester SPS Theory Examination April- 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={Draandexdrsmphar1stsemspsexamapr24}>
            Result of M. Pharm 1st Semester SPS (DRA & Ex- DRS) Examination, April 2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={mpharm1stsemSPSmainandsuppexamApr24}>
            Result of M. Pharm 1st Semester SPS (Main and Supple.) Examination April-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={Mpharm1stsemdipsarmainandsuppexamapr4}>
            Result of M. Pharm 1st Semester DIPSAR (Main and Supple.) Examination April-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={ResultofMBApm1stsemexamfeb24}>
            Result of MBA-PM 1st Semester Examinations, Feb-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMScaim3rdsemexamapr24}>
            Result of M. Sc. AIM 3rd Semester Examinations, April-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMBAITM1stsemexamfeb24}>
            Result of MBA-ITM 1st Semester Examinations, Feb-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofMscaimsemesterexamapr24}>
            Result of M. Sc. AIM 1st Semester Examinations, April-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={ResultofMBApm3rdsemestersuppexamfeb24}>
            Result of MBA-PM 3rd Semester Supplementary Examinations, Feb-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharmayrveda3rdSuppsemexamapr24}>
            Result of B. Pharm (Ayurveda) 3rd Semester Supplementary Examination, April-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpharmayrveda3rdsemexamapr24}>
            Result of B. Pharm (Ayurveda) 3rd Semester Examination, April-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={resultofbpt3rdsemmainandsuppexammarch24}>
            Result of  BPT 3rd Semester (Main & Supplementary) Examination, March-2024
            </a> <img src={imgGif} alt="new" />
            
          </li>
        <li>
            <a href={bscsportsscienceresult3rdsemsuppexam}>
            Result of B.Sc.(Hons.) Sports Science 3rd Semester Supplementary Examination, March-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBpharmayruveda5thsemexamfeb24}>
            Result of B. Pharm Ayurveda 5th Sem Examination February 2024
            </a> 
            
          </li>
        <li>
            <a href={bschonssportsscicene3rdsemexammar24}>
            Result of  B. Sc. (Hons.)  Sports Sciences 3rd Semester Examination, March- 2024
            </a> 
            
          </li>
        <li>
            <a href={bscsopetsscicene3rdsemsuppexammar24saurabh}>
            Result of  B. Sc. (Hons.)  Sports Sciences 3rd Semester Supplementary Examination, March- 2024 (Saurabh Kumar Gautam)
            </a> 
            
          </li>
        <li>
            <a href={resultofbmlt1stsemsuppexamfeb24}>
            Result of BMLT 1st Semester Supplementary examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofbmlt1stsemsuppoldschemeexamfeb24}>
            Result of BMLT 1st Semester Supplementary (Old Scheme) Examination, February -2024
            </a> 
            
          </li>
        <li>
            <a href={Bpt5thsemmainandsuppexammar24}>
            Result of BPT 5th Semester (Main & Supplementary) Examination, March- 2024
            </a> 
            
          </li>
        <li>
            <a href={resultOfMscspoetss3rdsemexammar24}>
            Result of  M.Sc. Sports Sciences 3rd Semester Examination, March- 2024
            </a> 
            
          </li>
        <li>
            <a href={resultofMBAhhm3rdsemexamfeb24}>
            Result of MBA-HHM 3rd Semester Examinations, Feb-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofmbaitm3rdsemexamfeb24}>
            Result of MBA-ITM 3rd Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofmbapm3rdsemexamfeb24}>
            Result of MBA-PM 3rd Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofMPT1stsemexamfeb24}>
            Result of MPH 1st Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBmlt1stsemexamfeb24}>
            Result of BMLT 1st Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofMBAhhm1stsemexamfeb24}>
            Result of MBA-HHM 1st Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBscbio1stexamfeb24}>
            Result of B.Sc. Biomedical 1st Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBBAch5thsemexamfeb24}>
            Result of BBA-CH 5th Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBscBiomedical1stsemsuppexamfeb24}>
            Result of B.Sc. Biomedical 1st Semester Supplementary Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBBACh1stsemsuppexamfeb24}>
            Result of BBA-CH 1st Semester Supplementary examination, February -2024
            </a> 
            
          </li>
        <li>
            <a href={resultOfbbaCh3rdsemsuppexamfeb24}>
            Result of BBA-CH 3rd Semester Supplementary examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={ResultofMPH3rdsemexamfeb24}>
            Result of MPH 3rd Semester Examination , February 2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBMLT3rdsemexmafeb24}>
            Result of BMLT 3rd Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBMLT3rdsemexmaSubbbbfeb24}>
            Result of BMLT 3rd Semester Supplemenatry Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBpharm3rdswemsuppledipsarjan24}>
            Result of B.Pharm 3rd Semester Supple. L.E (DIPSAR), January 2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBBahc3rdsemexamfeb24}>
            Result of BBA-HC 3rd Semester Examination, February -2024
            </a> 
            
          </li>
        <li>
            <a href={resultofMPTsportsmedicinepediaricscardiandpill3rdsemexammar24}>
            Result of MPT (Sports Medicine, Pediatrics, Cardiovascular and Pulmonary) 3rd Semester Examinations, March-2024
            </a> 
            
          </li>
        <li>
            <a href={mptsportsmedicinepediatricscariovascularandspullmonary1stsemexamfeb24}>
            Result of MPT (Sports Medicine, Pediatrics, Cardiovascular and Pulmonary) 1st Semester Examinations, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofbpt7thsemsuppexamfeb24}>
            Result of BPT 7th Semester Supplemenatary Examination, February- 2024
            </a> 
            
          </li>
        <li>
            <a href={bschonsbiomedicalscience3rdsemexamfeb24}>
            Result of B.Sc.(Hons) Biomedical Science 3rd Semester Examination, February- 2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBPT7thsemeamfeb24}>
            Result of BPT 7th Semester Examination, February- 2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBschonssportsscience1stsemsuppexamfeb24}>
            Result of B. Sc.(Hons.) Sports Science 1st semester supplementary Examination, February- 2024
            </a> 
            
          </li>
        <li>
            <a href={bpt1stsemexamfeb24}>
            Result of BPT 1st Semester Examination, February- 2024
            </a> 
            
          </li>
        <li>
            <a href={resultofbscHonssportsscience1stsemexamfeb24}>
            Result of  B. Sc.(Hons.) Sports Science 1st Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBpharm1stsemsuppexamspsjan24}>
            Result of B.Pharm 1st Semester Supplementary Examination (SPS), January 2024
            </a> 
            
          </li>
        <li>
            <a href={bpharm1stsemsuppexamdipsarjan24}>
            Result of B.Pharm 1st Semester Supplementary Examination (DIPSAR), January 2024
            </a> 
            
          </li>
        <li>
            <a href={corigendrumresultbpharm3rdsemlejan24}>
            Corrigendum Result of B.Pharm 3rd Semester Examination Lateral Entry, January 2024
            </a> 
            
          </li>
        <li>
            <a href={corigendrumresultbpharm3rdsemspsjan24}>
            Corrigendum Result of B.Pharm 3rd Semester (SPS), January 2024
            </a> 
            
          </li>
        <li>
            <a href={corigendrumresultbpharm3rdsemdipsajan24}>
            Corrigendum Result of B.Pharm 3rd Semester Examination (DIPSAR), January 2024
            </a> 
            
          </li>
        <li>
            <a href={corigendrumresultbpharm3rdsemdipsarlejan24}>
            Corrigendum Result of B.Pharm 3rd Semester Examination (DIPSAR) L.E, January 2024
            </a> 
            
          </li>
        <li>
            <a href={resultofbpharm1stsemexamdipsarjan24}>
            Result of B.Pharm 1st Semester Examination (DIPSAR), January 2024
            </a> 
            
          </li>
        <li>
            <a href={resultofbpharm1stsemexamspsjan24}>
            Result of B.Pharm 1st Semester Examination (SPS), January 2024
            </a> 
            
          </li>
        <li>
            <a href={resuofbschonssportsscience5thsemsuppexam}>
            Result of B. Sc. (Hons.) Sports Science 5th Semester Supplementary Examinations, Feb-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofbschons5thsemexamfeb24}>
            Result of B. Sc. (Hons.) Sports Science 5th Semester Examinations, Feb-2024
            </a> 
            
          </li>
        <li>
            <a href={resultofBpt1stsemsuppexamfeb24}>
            Result of BPT 1st Semester Supplementary Examinations, Feb-2024
            </a> 
            
          </li>
        <li>
            <a href={bmlt5thsemexamfeb24}>
            Result of BMLT 5th Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={dmlt3rdsemexamfeb24}>
            Result of DMLT 3rd Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={dmlt1stsemexamfeb24}>
            Result of DMLT 1st Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={dmlt1stsemsuppexamfeb24}>
            Result of DMLT 1st Semester Supplementary Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={bbaHc1stsemexamfeb24}>
            Result of BBA-HC 1st Semester Examination, February-2024
            </a> 
            
          </li>
        <li>
            <a href={bpharmayrveda1stsemsuppexamfeb24}>
            Result of B. Pharm(Ayurveda) (1st Semester Supplementary Examination , February- 2O24
            </a> 
            
          </li>
        <li>
            <a href={resultofBharmayrveda1tsemexanfeb24}>
            Result of B.Pharm(Ayurveda) (1st Semester Examination, February- 2024)
            </a> 
            
          </li>
        
          <li>
            <a href={Bpharm7thsemexamspsjan24}>
            Result of B.Pharm 7th Semester Examination SPS, January 2024
            </a> 
            
          </li>
          <li>
            <a href={bpharm7thsemexamdipsarjan24}>
            Result of B.Phatm 7th Semester Examination DIPSAR, January 2024
            </a> 
            
          </li>
          <li>
            <a href={resultOfbpharm7thsemsuppexamspsjan24}>
            Result of B.Pharm 7th Semester Supple. Examination SPS, January 2024
            </a> 
            
          </li>
          <li>
            <a href={revisedResultofBpt4thsemexamoct24}>
            Revised Result of BPT 4th Semester Examination, October-2024 (Ashima Gupta)
            </a> 
            
          </li>
         
          <li>
            <a href={resultofbpharm3rdsemleexamdipsarjan24}>
            Result of B.Pharm 3rd Semester L.E Examination DIPSAR, January 2024
            </a> 
            
          </li>
         
          <li>
            <a href={resultofbpharm3rdsemleexamspsjan24}>
            Result of B.Pharm 3rd Semester L.E Examination SPS, January 2024
            </a> 
            
          </li>
         
          <li>
            <a href={Resultofbphar3rdsemspsjan24}>
            Result of B.Pharm 3rd Semester Examination SPS, January 2024
            </a> 
            
          </li>
          <li>
            <a href={bpharm3rdsemdipsarresfeb24}>
            Result of B.Pharm 3rd Semester Examination DIPSAR, January 2024
            </a> 
            
          </li>
         
          <li>
            <a href={resultofBpharm3rdsemsuppDipsarjan24}>
            Result of B.Pharm 3rd Semester Supple. DIPSAR, January 2024
            </a> 
            
          </li>
          {/* <li>
            <a href={bpharm3rdsemsuppexamresdipsar24}>
            Result of B.Pharm 3rd Semester Supple. L.E DIPSAR, January 2024
            </a> 
            
          </li> */}
          <li>
            <a href={bpharm3rdsemsuppexamres24}>
            Result of B.Pharm 3rd Semester Supple. SPS, January 2024
            </a> 
            
          </li>
          <li>
            <a href={roBPharm5thsemsuppExamdipsarspsjan24}>
            Result of B.Pharm 5th Semester Supple. Examination (DIPSAR & SPS), January 2024
            </a> 
            
          </li>
          <li>
            <a href={resultOf5thsembpharmExamspsjan24}>
            Result of B.Pharm 5th Semester Examination (SPS), January 2024
            </a> 
            
          </li>
          <li>
            <a href={ResultOfBpharm5thsemexamDipsarjan24}>
            Result of B.Pharm 5th Semester Examination DIPSAR, January 2024
            </a> 
            
          </li>
         
          <li>
            <a href={resultoMMC1stsemsuppexamdec23}>
            Result of MMC 1st Semester Supplementary Examinations, Dec-2023
            </a> 
            
          </li>
         
          <li>
            <a href={resultoMMC2NDsemsuppexamdec23}>
            Result of MMC 2nd Semester Supplementary Examinations, Dec-2023
            </a> 
            
          </li>
          <li>
            <a href={resultoSaya1stsemsuppexamdec23}>
            Result of SAYA 1st Semester Supplementary Examinations, Dec-2023
            </a> 
            
          </li>
          <li>
            <a href={resultoSaya2ndsemsuppexamdec23}>
            Result of SAYA 2nd Semester Supplementary Examinations, Dec-2023
            </a> 
            
          </li>
          <li>
            <a href={ResultofDpharm2ndyearanuallexamdec2023}>
            Result of D.Pharm. 2nd Year Annual Examination December -2023
            </a> 
            
          </li>
         
          <li>
            <a href={resultofdpharm1styearsupexamdec2023}>
            Result of D.Pharm. 1st Year Supplementary Examination December -2023
            </a> 
            
          </li>
          <li>
            <a href={dPharm2ndYearsupppexamDec23}>
            Result of D.Pharm. 2nd Year Supplementary Examination December -2023
            </a> 
            
          </li>
          <li>
            <a href={resultofDphar2ndyearsuppoldschemede23}>
            Result of D.Pharm. 2nd Year Supplementary (Old Scheme) Examination, December -2023
            </a> 
            
          </li>
          <li>
            <a href={mbapm3rdsemsuppdec23}>
            MBA-PM 3rd Semester Supple.  Examination, December -2023
            </a> 
            
          </li>
          <li>
            <a href={mpharmindustrialPhar2ndsemsupexam}>
            Result of M.Pharm (Industrial Pharmacy) 2nd semester Supple. Exam , September-2023
            </a> 
            
          </li>
          <li>
            <a href={resofbpt8thsemdec23}>
            Result of BPT 8th Semester Examinations, Dec-2023
            </a> 
            
          </li>
          <li>
            <a href={resofbpt7thsemdec23sup}>
            Result of BPT 7th Semester Supplementary Examinations, Dec-2023
            </a> 
            
          </li>
          <li>
            <a href={resultofDMYSbatchII1stTrimester}>
            Result of DMYS Batch-II 1st Trimester Examination, October-2023
            </a> 
            
          </li>
          <li>
            <a href={resultofdmysbatch2ndtrinmen}>
            Result of DMYS Batch-II 2nd Trimester Examination, October-2023
            </a> 
            
          </li>
          <li>
            <a href={resultofdmysbatch3rdtrinmen}>
            Result of DMYS Batch-II 3rd Trimester Examination, October-2023
            </a> 
            
          </li>
          <li>
            <a href={resultofmscsportscience2ndsem}>
            Result of M.Sc Sports Sciences 2nd Semester Examinations, Sep-2023
            </a> 
            
          </li>
          <li>
            <a href={resultofdmysbatch1trim3rd}>
            Resuslt of DMYS Batch-I 3rd Trimester Examination, October-2023
            </a> 
            
          </li>
          <li>
            <a href={mptsportsmedicinecarandpulper2ndem}>
            Result of MPT (Sports Medicine,Cardiovascular and Pulmonary,Pediatrics) 2nd Semester Examinations, Sep-2023
            </a> 
            
          </li>
          <li>
            <a href={resultofbpt2ndsemexaminationoct2023}>
            Result of BPT 2nd Semester Examination, October -2023
            </a> 
            
          </li>
          <li>
            <a href={resultofbpt4thsemexamoct2023}>
            Result of BPT 4th Semester Examination, October -2023
            </a> 
            
          </li>
          <li>
            <a href={resultofbpharmayrveda4thsemOct2023}>
            Result of B.Pharm (Ayurveda) 4th Semester Examination, October -2023
            </a> 
            
          </li>
          <li>
            <a href={mpharmspsoct2023}>
            Result of M. Pharm SPS October 2023
            </a> 
            
          </li>
          <li>
            <a href={mpharmDipsaroct2023}>
            Result of M. Pharm DIPSAR October 2023
            </a> 
            
          </li>
          <li>
            <a href={bpharmayurveda2ndsemexam}>
            Result of B.Pharm (Ayurveda) 2nd Semester Examination, October -2023
            </a> 
            
          </li>
          <li>
            <a href={bpharmayurveda2ndsemsupp}>
            Result of B.Pharm (Ayurveda) 2nd Semester Supplementary Examination, October -2023
            </a> 
            
          </li>
          <li>
            <a href={drsesult4thsemdec2023}>
            Result of Exe- DRS M.Pharm 4th Semester Examination, December 2023
            </a> 
            
          </li>
          <li>
            <a href={mha4thsemexaminadec2023}>
            Result of MHA 4th Semesteer Examination-December, 2023
            </a> 
            
          </li>
          <li>
            <a href={mbapm4thsemexamdec2023}>
            Result of MBA-PM 4th Semesteer Examination-December, 2023
            </a> 
            
          </li>
          <li>
            <a href={mbaPet4thsemexaminationdec2023}>
            Result of MBA-PET 4th Semesteer Examination-December, 2023
            </a> 
            
          </li>
          <li>
            <a href={resultOfMph4thsemexamdec2023}>
            Result of MPH 4th Semesteer Examination-December, 2023
            </a> 
            
          </li>
          <li>
            <a href={resultOfmscAipm4thsemexamdec2023}>
            Result of M.Sc. AIPM 4th Semesteer Examination-December, 2023
            </a> 
            
          </li>
          <li>
            <a href={mpharm4thsemtemexaminationspsdev2023}>
            Result of M.Pharm 4th Semester Examination (SPS), December 2023
            </a> 
            
          </li>
          <li>
            <a href={mpharm4thsemexamniandipsardec2023}>
            Result of M.Pharm 4th Semester Examination (DIPSAR), December 2023
            </a> 
            
          </li>
          <li>
            <a href={rofmptsm4thsemexamspet2023}>
            Result of MPT (SM) 4th Semester Examinations, Sep-2023
            </a> 
            
          </li>
          <li>
            <a href={Resulofmscsportsscice4thsemsemeam}>
            Result of M. Sc. Sports Science 4th Semester Examinations, Sep-2023
            </a> 
            
          </li>
          <li>
            <a href={boharmsuppspssprt2023}>
            Result of B.Pharm 4th Semester Supple. Examination SPS, September 2023
            </a> 
            
          </li>
          <li>
            <a href={resultofboharm2ndsemexamseptdipsar}>
            Result of B.Pharm 2nd Semester Supple. Examination  DIPSAR, September 2023
            </a> 
            
          </li>
          <li>
            <a href={resultofbpharm4thsemsupsept}>
            Result of B.Pharm 4th Semester Supple. Examination SPS, September
            </a> 
            
          </li>
          <li>
            <a href={mansisharmarevisedmpharmmay2022}>
            Mansi Sharma Revised M.Pharm 1st Semester Result, May 2022
            </a> 
            
          </li>
          <li>
            <a href={resultofBpharm4thsemandleexaminadipsarsept}>
            Result of B. pharm 4th Semester and L.E. Examination DIPSAR, September 2023
            </a> 
            
          </li>
          <li>
            <a href={resultofbpharmleandsps}>
            Result of B. pharm 4th semester and L. E. Examination SPS, September 2023
            </a> 
            
          </li>
          <li>
            <a href={resultodmbaitm2ndsemsept2023}>
            Result of MBA-ITM 2nd Semester Examination, September -2023
            </a> 
            
          </li>
          <li>
            <a href={resultodmbapm2ndsemsept2023}>
            Result of MBA-PM 2nd Semester Supplementary Examination, September-2023
            </a> 
            
          </li>
          <li>
            <a href={resultofphdexaminationSUPOCT2023}>
            Result of Pre-PhD Supplementary Examination, October -2023
            </a> 
            
          </li>
          <li>
            <a href={bschonssopetscience2ndsem}>
            Result of B. Sc. (Hons.) Sports Science 2nd Semester Supple. Examinations, Sep-2023
            </a> 
            
          </li>
          <li>
            <a href={mph2ndsemexaminsept2023}>
            Result of MPH 2nd Semester Examination, September-2023
            </a> 
            
          </li>
          <li>
            <a href={mbahhm2ndsemexaminationsept2023}>
            Result of MBA-HHM 2nd Semester Examination, September-2023
            </a> 
            
          </li>
          <li>
            <a href={mbapm2ndyearsemexaminsept2023}>
            Result of MBA-PM 2nd Semester Examination, September-2023
            </a> 
            
          </li>
          <li>
            <a href={bpharmresult2ndsemsept}>
            RESULT OF B.PHARM 2ND SEMESTER SPS, SEPTEMBER 2023
            </a> 
            
          </li>
          <li>
            <a href={bhparmsecondsemdipsar}>
            RESULT OF B.PHARM 2ND SEMESTER EXAMINATION DIPSAR, SEPTEMBER
            </a> 
            
          </li>
          <li>
            <a href={resultofbscss2ndsemexamination}>
            Result of B. Sc. (Hons.) Sports Science 2nd Semester Examinations, Sep-2023
            </a> 
            
          </li>
          <li>
            <a href={mscAim2ndsemexaminationsept2023}>
            Result of M.Sc AIM 2nd Semester Examination, September -2023
            </a> 
            
          </li>

          <li>
            <a href={ResultOfBMLT4thsemExaminationsept}>
            Result of BMLT 4th Semester Examination, September -2023
            </a> 
            
          </li>

          <li>
            <a href={ResultOfDMLT4thsemExaminationsept}>
            Result of DMLT 4th Semester Examination, September -2023
            </a> 
            
          </li>


          <li>
            <a href={revisedResult}>
            Revised Result of MBA-ITM 1st Semester Examination, March-2023
            </a> 
            
          </li>


          <li>
            <a href={ResultOfBBAHC2ndSem}>
            Result of BBA-HC 2nd Semester Examination, Sept-2023
            </a> 
            
          </li>



          <li>
            <a href={ResultOfBBACHSupplementaryExamination}>
            Result of BBA-CH 2nd Semester Supplementary Examination, Sept-2023
            </a> 
            
          </li>



          <li>
            <a href={ResultOfBMLT2ndSemSupplementary}>
            Result  of BMLT 2nd Semester Supplementary Examination, Sept-2023
            </a> 
            
          </li>



          <li>
            <a href={ResultOfMBAPet2ndSemSuppExam}>
            Result of MBA (PET) 2nd Semester Supplementary Examination, Sept-2023
            </a> 
            
          </li>








          <li>
            <a href={ResultOfBmlt2ndsemsept}>
            Result of BMLT 2nd Semester Examination, September-2023.
            </a> 
            
          </li>


          <li>
            <a href={ResultOfBscBiomedicalSciencesept}>
            Result of B.Sc. (Biomedical Science) 2nd Semester Examination, September-2023
            </a> 
            
          </li>


          <li>
            <a href={ResultOfDmlt2ndsemsept2}>
            Result of DMLT 2nd Semester Examination, September-2023
            </a> 
            
          </li>


          <li>
            <a href={ResultOfDmlt2ndsemsept}>
            Result of DMLT 2nd Semester Supple. Examination, September-2023
            </a> 
            
          </li>


          <li>
            <a href={ResultOfBBACh4thsemsept}>
            Result of BBA- CH 4th Semester Examination, September-2023
            </a> 
            
          </li>









          <li>
            <a href={nnneww001}>
              Result of B.Pharm 7th Semester Old Batch (DIPSAR), March
              2022
            </a> 
            
          </li>










          <li>
            <a href={nnneww002}>
              Result of B.Pharm 8th Semester Examination (DIPSAR), August
              2023
            </a>
            
          </li>
          <li>
            <a href={nnneww003}>
              Result of B.Pharm 6th Semester Examination DIPSAR, August
              2023
            </a>
            
          </li>
          <li>
            <a href={nnneww004}>
              Result of B.Pharm 6th Semester Supple. Examination DIPSAR, August
              2023
            </a>
            
          </li>
          <li>
            <a href={nnneww005}>
              Result of B.Pharm 6th Semester Supple. Examination (SPS), August
              2023
            </a>
            
          </li>
          <li>
            <a href={nnneww006}>
              Result of B.Pharm 6th Semester Examination (SPS), August
              2023
            </a>
            
          </li>
          <li>
            <a href={nnneww007}>
              Result of B.Pharm 8th Semester Examination (SPS), August
              2023
            </a>
            
          </li>
          <li>
            <a href={nnneww008}>
              Result of B. Sc. (Hons.) Sports Science 4th Semester Supplementary
              Examinations, Aug-2023
            </a>
            
          </li>
          <li>
            <a href={nnneww009}>
              Result of B. Sc. (Hons.) Sports Science 4th Semester Examinations,
              Aug-2023
            </a>
            
          </li>

          <li>
            <a href={rec01}>
              Result of M.Pharm 2nd Semester Supple. Exam. (Industrial
              Pharmacy), August 2023
            </a>
          </li>
          <li>
            <a href={oldres01}>
              Revised Result of MBA-HHM 1st Semester Examination, March-2023
            </a>
          </li>
          <li>
            <a href={oldres02}>
              REVISED RESULT OF M.PHARM 1ST SEMESTER SUPPLE. EXAM. (INDUSTRIAL
              PHARMACY) SPS, APRIL 2023
            </a>
          </li>
          <li>
            <a href={oldres03}>
              Result of BPT 6th Semester Examination, July-2023
            </a>
          </li>
          <li>
            <a href={oldres04}>
              Result of D.Pharm. 1st Year Annual Examination July-2023
            </a>
          </li>
          <li>
            <a href={oldres05}>
              Result of D.Pharm. 1st Year Supplementary Examination July-2023
            </a>
          </li>
          <li>
            <a href={oldres06}>
              Result of B. Sc. (Hons.) Sports Science 3rd Semester Supplementary
              Examination, Aug-2023
            </a>
          </li>
          <li>
            <a href={oldres07}>
              Result of BPT 8th Semester Examination, July-2023
            </a>
          </li>
          <li>
            <a href={oldres08}>
              Result of B. Sc. (Hons.) 6th Semester Examination, July-2023
              (Pranav Dalal)&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres09}>
              Result of B. Sc. (Hons.) Sports Science 6th Semester Examination,
              July-2023
            </a>
          </li>
          <li>
            <a href={oldres10}>
              Result of B. Sc. (Hons.) Sports Science 6th Semester Supplementary
              Examination, July-2023
            </a>
          </li>
          <li>
            <a href={oldres11}>
              Result of D. Pharm. 2nd Year Examination June-2023
            </a>
          </li>
          <li>
            <a href={oldres12}>
              Result of D. Pharm. 2nd Year Supplementary June -2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres13}>
              RESULT OF B.PHARM 8TH SEMESTER EXAMINATION (SPS), JUNE 2023
            </a>
          </li>
          <li>
            <a href={oldres14}>
              RESULT OF B.PHARM 8TH SEMESTER EXAMINATION (DIPSAR), JUNE 2023
            </a>
          </li>
          <li>
            <a href={oldres15}>
              Result of B. Pharm (Ayurveda) 1st Semester Supplementary
              Examination, April-2023
            </a>
          </li>
          <li>
            <a href={oldres16}>
              Result of M.Sc AIPM 3rd Semester Examination, April-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres17}>
              RESULT OF M.PHARM 1ST SEMESTER EXAMINATION DIPSAR, APRIL-2023
            </a>
          </li>
          <li>
            <a href={oldres18}>
              RESULT OF M.PHARM 1ST SEMESTER EXAMINATION (SPS), APRIL 2023
            </a>
          </li>
          <li>
            <a href={oldres19}>
              RESULT OF M.PHARM 1ST SEMESTER SUPPLE. EXAM. (SPS), APRIL 2023
            </a>
          </li>
          <li>
            <a href={oldres20}>
              RESULT OF M PHARM 3RD SEMESTER EXAM. (SPS), MAY 2023
            </a>
          </li>
          <li>
            <a href={oldres21}>
              Result of M. Pharm 3rd semester Exam DIPSAR, April 2023
            </a>
          </li>
          <li>
            <a href={oldres22}>
              RESULT OF B.PHARM 2ND SEMESTER SUPPLE. EXAMINATION DIPSAR, APRIL
              2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres23}>
              RESULT OF B.PHARM 2ND SEMESTER SUPPLE. EXAMINATION SPS, APRIL
              2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres24}>
              Result of M. Sc. Sports Science 1st Semester Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={oldres25}>
              Result of M. Sc. Sports Science 3rd Semester Examination,
              April-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres26}>
              RESULT OF B.PHARM 2ND SEMESTER SUPPLE. EXAMINATION SPS, APRIL 2023
            </a>
          </li>
          <li>
            <a href={oldres27}>
              RESULT OF B.PHARM 3RD SEMESTER SUPPLE. EXAMINATION DIPSAR, APRIL
              2023
            </a>
          </li>
          <li>
            <a href={oldres28}>
              Result of B. Pharm (Ayurveda) 1st Semester Examination, April-2023
            </a>
          </li>
          <li>
            <a href={oldres29}>
              Result of M.Sc. AIM 1st Semester Examination, April-2023
            </a>
          </li>
          <li>
            <a href={oldres30}>
              Result of MBA-ITM 1st Semester Examination, March-2023
            </a>
          </li>
          <li>
            <a href={oldres31}>
              Result of BPT 2nd Semester Supplementary Examination,
              April-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres32}>
              Result of MBA-PM 1st Semester Examination, March-2023
            </a>
          </li>
          <li>
            <a href={oldres33}>
              Result of B. Pharm 7th Semester Examination Old Batch (DIPSAR),
              June-2023
            </a>
          </li>
          <li>
            <a href={oldres34}>
              Result of B. Pharm 7th Semester Examination (DIPSAR) June-2023
            </a>
          </li>
          <li>
            <a href={oldres35}>
              Result of BPT 8th Semester Supplementary Examination,
              April-2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres36}>
              Result of BPT 6th Semester Supplementary Examination,
              April-2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres37}>
              Result of BPT 4th Semester Supplementary Examination,
              April-2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres38}>
              Result of B. Sc. (Hons.) Sports Science 1st Semester Supplementary
              Examination, April-2023
            </a>
          </li>
          <li>
            <a href={oldres39}>
              Result of B. Sc. (Hons.) Sports Science 1st Semester Examination,
              April-2023
            </a>
          </li>
          <li>
            <a href={oldres40}>
              Result of B. Pharm 8th Semester&nbsp; Examination Old Batch (SPS),
              June 2023
            </a>
          </li>
          <li>
            <a href={oldres41}>
              Result of B. Pharm 6th Semester Supple. Examination Old Batch
              (SPS), April 2023
            </a>
          </li>
          <li>
            <a href={oldres42}>
              Result of B. Pharm 6th Semester Supple. Examination (DIPSAR),
              April 2023
            </a>
          </li>
          <li>
            <a href={oldres43}>
              Result of B. Pharm 4th Semester Supple. Examination L.E (SPS),
              April 2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres44}>
              Result of B. Pharm 4th Semester Supple. Examination (SPS), April
              2023
            </a>
          </li>
          <li>
            <a href={oldres45}>
              Result of B. Pharm 4th Semester Supple. Examination (DIPSAR),
              April 2023
            </a>
          </li>
          <li>
            <a href={oldres46}>
              Result of B. Pharm 6th Semester Supple. Examination (SPS), April
              2023
            </a>
          </li>
          <li>
            <a href={oldres47}>
              Result of B. Pharm 4th Semester Supple. Examination Old Batch
              (DIPSAR), April 2023
            </a>
          </li>
          <li>
            <a href={oldres48}>
              Result of B. Pharm (Ayurveda) 3rd Semester Examination, April-2023
              &nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres49}>
              Result of MPH 1st Semester Examination, March-2023 &nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres50}>
              Result of MBA-PM 1st Semester Supplementary Examination,
              April-2023 &nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres51}>
              Result of MBA-HHM 1st Semester Examination, March-2023
              &nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres52}>
              Result of MBA-PET 1st Semester Supplementary Examination,
              March-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres53}>
              Result of B. Pharm 3rd Semester Supplementary Examination SPS,
              April 2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres54}>
              Result of B. Pharm 3rd Semester &amp; Lateral Entry Examination
              SPS, April 2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres55}>
              Result of B. Pharm 3rd Semester &amp; Lateral Entry Examination
              DIPSAR, April 2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres56}>
              Result of BPT 3rd Semester Examination, March-2023
            </a>
          </li>
          <li>
            <a href={oldres57}>
              Result of BPT 1st Semester Examination, March-2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres58}>
              Result of BPT 1st Semester Supplementary Examination, March-2023
              &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres59}>
              Result of DMLT 1st Semester Supplementary Examination,
              April-2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres60}>
              Result of BBA-CH 1st Semester Supplementary Examination,
              March-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres61}>
              Result of BMLT 1st Semester Supplementary Examination, April-2023
              &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres62}>
              Result of BMLT 1st Semester Examination, April-2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres63}>
              Result of BBA-HC 1st Semester Examination, March-2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres64}>
              Result of MPT (Sports Medicine, Cardiovascular and Pulmonary,
              Pediatrics) 1st Semester Examination, March-2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres65}>
              Result of DMLT 1st Semester Examination, April-2023&nbsp; &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres66}>
              Result of B. Pharm 8th Semester Supple. Examination (SPS), April
              2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres67}>
              Result of MPT (SM) 3rd Semester Examination, March-2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres68}>
              Revised Result of DMYS 3rd Trimester October -2022 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres69}>
              Result of B. Pharm 5th Semester Examination DIPSAR, March 2023
              &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres70}>
              Result of B. Pharm 5th Semester Examination SPS, March 2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres71}>
              Result of B. Sc. (Hons.) Sports Science 6th Semester Examination,
              July-2022 (Result Later Students) &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres72}>
              Result of MBA PET 3rd Semester Examination, March-2023
            </a>
          </li>
          <li>
            <a href={oldres73}>
              Result of B.Sc. (Hons) Biomedical Science 1st Semester
              Examination, March-2023{" "}
            </a>
          </li>
          <li>
            <a href={oldres74}>
              Result of MPH 3rd Semester Examination, March-2023{" "}
            </a>
          </li>
          <li>
            <a href={oldres75}>
              Result of MHA 3rd Semester Examination, March-2023{" "}
            </a>
          </li>
          <li>
            <a href={oldres76}>
              Result of MBA-PM 3rd Semester Examination, March-2023{" "}
            </a>
          </li>
          <li>
            <a href={oldres77}>
              Result of BBA-CH 3rd Semester examination, March-2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres78}>
              Result of BMLT 3rd Semester examination March-2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres79}>
              Result of DMLT 3rd Semester examination, March-2023
            </a>
          </li>
          <li>
            <a href={oldres80}>
              Result of B. Pharm 1st Semester Examination SPS, March 2023
            </a>
          </li>
          <li>
            <a href={oldres81}>
              Result of B. Pharm 1st Semester Examination DIPSAR, March
              2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres82}>
              Result of B. Sc. (Hons.) 3rd Semester Supplementary Examination,
              Feb-2023 &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres83}>
              Result &nbsp;of B. Sc. (Hons.) Sports Science 5th Semester
              Supplementary Examination, Feb-2023
            </a>
          </li>
          <li>
            <a href={oldres84}>
              Result of B. Sc. (Hons.) Sports Science 3rd Semester Supplementary
              Examination, Feb-2023 (Old Batch Student)
            </a>
          </li>
          <li>
            <a href={oldres85}>Result of Pre. Ph.D Examination, Feb-2023</a>
          </li>
          <li>
            <a href={oldres86}>
              Result of SAYA (WCSC) 2nd Semester Examination, January-2023
            </a>
          </li>
          <li>
            <a href={oldres87}>
              Result of MMC (WCSC) 2nd Semester Examination, January-2023
            </a>
          </li>
          <li>
            <a href={oldres88}>
              Result of B. Sc. (Hons.) Sports Science 3rd Semester Examination,
              February-2023.&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres89}>
              Result of B. Sc. (Hons.) Sports Science 5th Semester Examination,
              February-2023.&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres90}>
              Result of D.Pharm 1st Year (Supplementary) Examination,
              February-2023
            </a>
          </li>
          <li>
            <a href={oldres91}>
              Result of BPT 5th Semester Examination, January- 2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres92}>
              Result of B.Pharm 7th Semester Examination DPSRU, February 2023
            </a>
          </li>
          <li>
            <a href={oldres93}>
              Result of B.Pharm 7th Semester Examination DIPSAR, February 2023
            </a>
          </li>
          <li>
            <a href={oldres94}>
              Result of BPT 7th Semester Examination, January-2023
            </a>
          </li>
          <li>
            <a href={oldres95}>
              Result of B.Pharm 5th Semester Supplementary Examination Old Batch
              DIPSAR, January- 2023&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres96}>
              Result of B. Pharm 5th Semester Supplementary Examination DPSRU,
              January-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres97}>
              Result of B. Pharm 5th Semester Supplementary Examination DIPSAR,
              January-2023
            </a>
          </li>
          <li>
            <a href={oldres98}>
              Result of B. Pharm 3rd Semester Supplementary Examination DIPSAR
              Old Batch, January-2023
            </a>
          </li>
          <li>
            <a href={oldres99}>
              Result of B. Pharm 3rd Semester Supplementary Examination DIPSAR,
              January-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres100}>
              Result of B. Pharm 1st Semester Supplementary Examination DPSRU,
              January-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres101}>
              Result of B. Pharm 1st Semester Supplementary Examination DIPSAR,
              January-2023
            </a>
          </li>
          <li>
            <a href={oldres102}>
              Result of BPT 3rd Semester Supplementary Examination,
              January-2023&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres103}>
              Result of BPT 1st Semester Supplementary Examination, January-
              2023
            </a>
          </li>
          <li>
            <a href={oldres104}>
              Result of DMYS(Batch-1) 3rd Trimester Supplementary Examination,
              October-2022&nbsp; &nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres105}>
              Result of B.Pharm 2nd Semester Supplementary Examination DPSRU.
              October 2022
            </a>
          </li>
          <li>
            <a href={oldres106}>
              Result of BPT 2nd Semester Supplementary Examination, Sep-2022
            </a>
          </li>
          <li>
            <a href={oldres107}>
              Result of B. Pharm 2nd Semester Supplementary Examination, October
              – 2022.
            </a>
          </li>
          <li>
            <a href={oldres108}>
              Result of BPT 4th Semester Supplementary Examination,
              August-2022&nbsp; &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres109}>
              Result of B. Sc. (Hons.) Sports Science 4th Semester Supplementary
              Examination, August-2022&nbsp; &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres110}>
              Result of B. Pharm 6th Semester Supplementary Examination August
              DPSRU 2022
            </a>
            .
          </li>
          <li>
            <a href={oldres111}>
              Result of B.Pharm 8th Semester Supplementary Examination Old Batch
              DPSRU, August 2022
            </a>
          </li>
          <li>
            <a href={oldres112}>
              Result of B.Pharm 7th Semester Supplementary Examination Old Batch
              DPSRU, August 2022
            </a>
          </li>
          <li>
            <a href={oldres113}>
              Result of D. Pharm 2nd Year Supplementary Examination, August 2022
            </a>
          </li>
          <li>
            <a href={oldres114}>
              Result of B. Pharm 7th Semester Supplementary Examination,
              (DIPSAR) August -2022.
            </a>
          </li>
          <li>
            <a href={oldres115}>
              Result of B. Pharm 7th Semester Supplementary Examination, (DPSRU)
              August -2022.
            </a>
          </li>
          <li>
            <a href={oldres116}>
              Result of DMYS Trimester -2 (Batch 1) Supplementary Examination,
              June 2022
            </a>
          </li>
          <li>
            <a href={oldres117}>
              Result of DMYS Trimester -1 (Batch1 &amp; 2) Supplementary
              Examinaton, June 2022
            </a>
          </li>
          <li>
            <a href={oldres118}>
              Result of B.Pharm 1st Semester Supplementary Examination DPSRU
              April 2022
            </a>
          </li>
          <li>
            <a href={oldres119}>
              Result of B.Pharm 7th Semester Supplementary Examination DIPSAR
              March 2022
            </a>
          </li>
          <li>
            <a href={oldres120}>
              Result of M.Pharm&nbsp; Hospital Pharmacy 2nd Semester
              Supplementary Examination DIPSAR June 2022
            </a>
          </li>
          <li>
            <a href={oldres121}>
              Result of B. Sc. (Hons.) Sports Sciences 1st Semester
              Supplementary Examination, May-2022
            </a>
          </li>
          <li>
            <a href={oldres122}>
              Result of B.Pharm 3rd Semester Supplementary Exam DIPSAR, March
              2022
            </a>
          </li>
          <li>
            <a href={oldres123}>
              Result of B. Sc. (H.) Sports Sciences 3rd Semester Supplementary
              Examination, March-2022
            </a>
          </li>
          <li>
            <a href={oldres124}>
              Result of Jyotsna and Vivek Kumar Gupta B.Pharm 1st Semester
              Supplementary Examination, January 2022
            </a>
          </li>
          <li>
            <a href={oldres125}>
              Result of BPT 7th Semester Supplementary Examination, January-2022
            </a>
          </li>
          <li>
            <a href={oldres126}>
              Result of BPT 5th Semester Supplementary Examination, January-2022
            </a>
          </li>
          <li>
            <a href={oldres127}>
              Result of MPCR 1st Semester Supplementary Examination DIPSAR,
              January 2022
            </a>
          </li>
          <li>
            <a href={oldres128}>
              Result of MQA 1st Semester Supplementary Examination DIPSAR,
              January 2022
            </a>
          </li>
          <li>
            <a href={oldres129}>
              Result of MIP 1st Semester Supplementary Examination DPSRU,
              January 2022
            </a>
          </li>
          <li>
            <a href={oldres130}>
              Result of MPB 1st Semester Supplementary Examination DPSRU,
              January 2022&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres131}>
              Result of B.Pharm 5th Semester Supplementary Examination DPSRU,
              January 2022
            </a>
          </li>
          <li>
            <a href={oldres132}>
              Result of B.Pharm 5th Semester Supplementary Examination DIPSAR,
              January 2022&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres133}>
              Result of B.Pharm 3rd Semester Supplementary Examination DPSRU,
              January 2022
            </a>
          </li>
          <li>
            <a href={oldres134}>
              Result of B.Pharm 3rd Semester Supplementary Examination L.E
              DIPSAR, January 2022
            </a>
          </li>
          <li>
            <a href={oldres135}>
              Result of Fatima Subhi B.Pharm 3rd Semester Supplementary
              Examination, January 2022&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres136}>
              Result of Ravi Kumar Yadav B.Pharm 3rd Semester Supplemenatary
              Examination, January 2022&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres137}>
              Result of B.Pharm 3rd Semester Supplementary Examination DIPSAR,
              January 2022
            </a>
          </li>
          <li>
            <a href={oldres138}>
              Result of B.Pharm 1st Semester Supplementary Examination DPSRU,
              January 2022
            </a>
          </li>
          <li>
            <a href={oldres139}>
              Result of B.Pharm 1st Semester Supplementary Examination DIPSAR,
              January 2022&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres140}>
              Result of BPT 3rd Semester Supplementary Examination, January-2022
            </a>
          </li>
          <li>
            <a href={oldres141}>
              Result of BPT 1st Semester Supplementary Examination, January-2022
            </a>
          </li>
          <li>
            <a href={oldres142}>
              Result of B. Sc. Sports Science 6th Semester Supplementary
              Examination, December-2021
            </a>
          </li>
          <li>
            <a href={oldres143}>
              Result of B. Sc. Sports Science 4th Semester Supplementary
              Examination, August-2021
            </a>
          </li>
          <li>
            <a href={oldres144}>
              Result of B. Sc. Sports Science 2nd Semester Supplementary
              Examination, September-2021
            </a>
          </li>
          <li>
            <a href={oldres145}>
              Result of B. Pharm (DIPSAR/DPSRU) 2nd Semester Supplementary
              Examination, September-2021
            </a>
          </li>
          <li>
            <a href={oldres146}>
              Result of BPT 2nd Semester Supplementary Examination,
              September-2021
            </a>
          </li>
          <li>
            <a href={oldres147}>
              Result of D.Pharm 1st Year Supple. Exam 2019 Batch
            </a>
          </li>
          <li>
            <a href={oldres148}>
              Result of D.Pharm 1st Year Supple. Exam 2018 Batch
            </a>
          </li>
          <li>
            <a href={oldres149}>
              Result of B.Pharm 3rd Sem Supple.&nbsp; Exam DPSRU, 2021&nbsp;
              &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres150}>
              Result of B.Pharm 2nd Sem Supple.&nbsp; Exam DPSRU, 2021&nbsp;
              &nbsp;
            </a>
          </li>
          <li>
            <a href={oldres151}>
              Result of B.Pharm 2nd Sem Supple.&nbsp; Exam DIPSAR, 202
            </a>
            1
          </li>
          <li>
            <a href={oldres152}>
              Result of 4th Semester Supple. Exam DIPSAR, February 2021
            </a>
          </li>
          <li>
            <a href={oldres153}>
              Result of M.Pharm 2nd Sem Supple. Exam (MPB) DPSRU, March 2021
            </a>
          </li>
          <li>
            <a href={oldres154}>
              Result of BPT 2nd Sem Supple.&nbsp; Exam 2018 DPSRU, March 2021
            </a>
          </li>
          <li>
            <a href={oldres155}>
              Result of B.Pharm 3rd Sem Supple.&nbsp; Exam DIPSAR, Feb.
              2021&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres156}>
              Result of B.Pharm 6th Sem Supple.&nbsp; Exam DPSRU, Feb.
              2021&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres157}>
              Result of B.Pharm 7th Sem Supple.&nbsp; Exam DPSRU, Feb.
              2021&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres158}>
              Result of B.Pharm 7th Sem Supple.&nbsp; Exam DIPSAR, Feb. 2021
            </a>
          </li>
          <li>
            <a href={oldres159}>
              Result of M.Pharm 2nd Semester Supple. Exams (DPSRU)
              Nov.2021&nbsp;&nbsp;
            </a>
          </li>
          <li>
            <a href={oldres160}>
              Result of M.Pharm 1st Semester Supple. Exams (DPSRU) Nov.
              2021&nbsp;
            </a>
          </li>
        </ul>
        {/* pdfLink */}
      </div>

      {/* old data link  */}

      <h2 className="dpsru-secondary-heading-for-every-page">
        Examination Results
      </h2>

      <div className="nirf-data-container">
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/examination-result"
            className="nirf-botton library-navlink library-navlink active"
          >
            {" "}
            2023
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/result-2022"
            className="nirf-botton library-navlink library-navlink active"
          >
            {" "}
            2022
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/result-2021"
            className="nirf-botton library-navlink library-navlink active"
          >
            {" "}
            2021
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link to='/result-2020' className="nirf-botton library-navlink library-navlink active">
            2020
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/result-2019"
            className="nirf-botton library-navlink library-navlink active"
          >
            {" "}
            2019
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/examination/result-2018"
            className="nirf-botton library-navlink library-navlink active"
          >
            {" "}
            2018
          </Link>
        </section>
      </div>
    </div>
  );
}

export default Results;
